import { useEffect } from 'react';

// compare name of object, return bool
export function compareValue(name, value) {
  return name === value;
}

//regex for email
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

//check email for ending domain
export function emailHasDomain(email, domain) {
  return email.split('@')[1].indexOf(domain) !== -1;
}

//regex for web address
export function validateUrl(url) {
  return /^((https?|ftp):\/\/)?(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!$&amp;&#39;()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!$&amp;&#39;()*+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!$&amp;&#39;()*+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!$&amp;&#39;()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-fA-F]{2})|[!$&amp;&#39;()*+,;=]|:|@)|\/|\?)*)?$/i.test(
    url
  );
}

//open new window for url
export function openNewWindow(url, width, height) {
  var leftPosition, topPosition;
  //Allow for borders.
  leftPosition = window.screen.width / 2 - (width / 2 + 10);
  //Allow for title and status bars.
  topPosition = window.screen.height / 2 - (height / 2 + 50);
  //Open the window.
  window.open(
    url,
    "Window2",
    "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"
  );
}

//get date, mm/dd/yy
export function getTodaysDate() {
  const dateObj = new Date(),
    month = dateObj.getUTCMonth() + 1, //months from 1-12
    day = dateObj.getUTCDate(),
    year = dateObj.getUTCFullYear(),
    fullDate = `${month}/${day}/${year}`;

  return fullDate;
}

export const useSetTitle = (title) => {
  useEffect(() => {
    const ptitle = title;
    // console.log({ ptitle });
    document.title = ptitle;
    //check for satellite object per https://webinfo.cdc.gov/display/WCMSSP/CDC+Metrics+-+Adobe+Launch
    if (window.hasOwnProperty('_satellite')) {
      var dataObject = {};
      var _satellite = window._satellite;
      dataObject.ch = 'DDT DPRP Application/Registry';
      dataObject.pageName = document.title;
      _satellite.track('pageview', dataObject);
    }
    // use dependency array to only run useEffect once per title change (needed for metrics)
  }, [title]);
}