import React from "react";

const Overlay = ({ message, center = false, cssClass = "" }) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "#000000",
          opacity: 0.2,
          zIndex: 1
        }}
      />
    </>
  );
};

export default Overlay;
