import React from "react";
import { Container } from "reactstrap";
import { NavMenu } from "../layout/NavMenu";
import { Header, Footer, Title } from "template-package-react";
import { useLocation } from "react-router-dom";

export function Layout(props) {
  let location = useLocation();

  return (
    <div>
      <Header />
      <Title
        title={
          location.pathname.indexOf("/registry") === -1
            ? "Diabetes Prevention Recognition Program Application"
            : "Diabetes Prevention Recognition Program Registry"
        }
      />
      <NavMenu />
      <Container>{props.children}</Container>
      <Footer />
    </div>
  );
}
