export default {
  //before you begin section
  ACKNOWLEDGE_SOP_IND: "AcknowledgeSOPInd",
  ACKNOWLEDGE_OCA_IND: "AcknowledgeOCAInd",
  ACKNOWLEDGE_HEGPIC_IND: "AcknowledgeHEGPICInd",
  ACKNOWLEDGE_SOP_IND_VALIDATE_MSG: "You must acknowledge before submitting your application.",
  ACKNOWLEDGE_OCA_IND_VALIDATE_MSG: "You must acknowledge before submitting your application.",
  ACKNOWLEDGE_HEGPIC_IND_VALIDATE_MSG: "You must acknowledge before submitting your application.",

  //General info
  APPLICATION_STATUS_ID: "ApplicationStatusId",
  ADDRESS_VALIDATED_IND: "AddressValidatedInd",
  CURRICULUM_DESCRIPTION: "CurriculumDescription",
  FORMATTED_ADDRESS: "formattedAddress",
  FORMATTED_CLASS_TYPE: "formattedClassType",
  FORMATTED_PHONE: "formattedPhoneNumber",
  FORMATTED_ZIP: "formattedZip",
  ORG_NAME: "orgName",
  ORG_CATEGORIES: "OrganizationCategoryId",
  ORG_PHONENUMBER: "OrgPhoneNumber",
  ORG_PHONE_AREA_CODE: "OrgPhoneAreaCode",
  ORG_PHONE3: "OrgPhone3",
  ORG_PHONE4: "OrgPhone4",
  ORG_PHONE_EXT: "OrgPhoneExt",
  ORG_PUBLIC_STATUS: "orgPublicStatus",
  ORG_EMAIL: "OrgEmail",
  ORG_EMAIL_SORT: "orgEmail",
  ORG_EMAIL_INVALID_DOMAIN: "cdc.gov",
  ORG_EMAIL_DOMAIN_VALIDATION_MSG: "This email address cannot be added because it is a CDC email address.",
  EMAIL_NOT_VALID_MSG: "Incorrect Email Format",
  ORG_ADA_TYPE: "ADARecogDSMESInd",
  ORG_ADCES_TYPE: "ADCESAccredDSMESInd",
  PHYSICAL_ADDRESS: "PhysicalAddress",
  PHYSICAL_ADDRESS2: "PhysicalAddress2",
  PHYSICAL_CITY: "PhysicalCity",
  PHYSICAL_ZIP4: "PhysicalZip4",
  PHYSICAL_ZIP5: "PhysicalZip5",
  PHYSICAL_STATE_ID: "PhysicalStateId",
  PHYSICAL_STATE_ABBR: "State",
  PREV_ASSIGNED_ORG_CODE: "PreviousAssignedCode",
  REAPPLY: "Reapply",
  UMBRELLA_NAME: "umbrellaName",
  WEBADDRESS: "webAddress",

  //General info validate message text
  APPLICATION_STATUS_ID_VALIDATE_MSG: "Type of application is required",
  CONTACT_EMAIL_VALIDATE_MSG: "Email is required",
  INVALID_URL_VALIDATE_MSG:
    "Invalid entry - Please enter a valid web address or URL.",
  ORG_NAME_VALIDATE_MSG: "Organization name is required",
  ORG_CATEGORIES_VALIDATE_MSG: "Organization type is required",
  ORG_PHONENUMBER_VALIDATE_MSG: "Phone number is required",
  PHYSICAL_ADDRESS_VALIDATE_MSG: "Street address is required",
  PHYSICAL_CITY_VALIDATE_MSG: "City is required",
  PHYSICAL_STATE_ID_VALIDATE_MSG: "State is required",
  PHYSICAL_ZIP5_VALIDATE_MSG: "Zip code is required",
  PREV_ASSIGNED_ORG_CODE_VALIDATE_MSG: "Previous OrgCode is required",
  PREV_ASSIGNED_ORG_CODE_VALIDATE_TYPE_INVALID_MSG: "Invalid format",

  //Program Coordinator
  CONTACT_TITLE: "ApplicantTitleId",
  CONTACT_TITLE_OTHER: "ApplicantTitle",
  CONTACT_FIRST_NAME: "ContactNameFirstName",
  CONTACT_MID_NAME: "ContactNameMi",
  CONTACT_LAST_NAME: "ContactNameLastName",
  CONTACT_SALUTATION: "ContactNameSalutation",
  CONTACT_PHONE: "ContactPhone",
  CONTACT_PHONE_AREA_CODE: "ContactPhoneAreaCode",
  CONTACT_PHONE3: "ContactPhone3",
  CONTACT_PHONE4: "ContactPhone4",
  CONTACT_PHONE_EXT: "ContactPhoneExt",
  CONTACT_EMAIL: "ContactEmail",
  CONTACT_EMAIL_VERIFY: "ContactEmailVerify",

  //contact info validation text
  CONTACT_EMAIL_EMAIL_VERIFY_VALIDATE_MSG: "Email is required",
  CONTACT_EMAIL_VERIFY_FAIL_MSG: "Email address does not match",
  CONTACT_FIRST_NAME_VALIDATE_MSG: "First name is required",
  CONTACT_LAST_NAME_VALIDATE_MSG: "Last name is required",
  CONTACT_TITLE_VALIDATE_MSG: "Title is required",
  CONTACT_PHONE_VALIDATE_MSG: "Phone number is required",

  //type of delivery
  NATIONAL_ONLINE_PROVIDER: "NationalOnLineProvider",
  DELIVERY_MODE: "DeliveryModeId",
  DELIVERY_MODE_OFFER_APP_ACCESS: "ProgramAccessInd",
  DELIVERY_MODE_OFFER_APP_ACCESS_TXT:
    "Do you offer participants an app to access your diabetes prevention program?",
  DELIVERY_MODE_MAIN_MECHANISM_MODE: "DELIVERY_MODE_MainMechanismMode",
  DELIVERY_MODE_MAIN_MECHANISM_MODE_TXT:
    "What will be the main mechanism/mode used for real-time coach interaction?",
  DELIVERY_MODE_LOCKED_MODULE: "ModulesLockedInd",
  DELIVERY_MODE_LOCKED_MODULE_TXT:
    "Are modules kept locked to control for the intensity of the program as per Requirement 4 of the DPRP Standards?",
  DELIVERY_MODE_ACCESS_PASSED: "ModulesAccessInd",
  DELIVERY_MODE_ACCESS_PASSED_TXT:
    "Do participants have access to modules once the week has passed?",
  DELIVERY_MODE_SKIP_MODULES: "ModulesSkipInd",
  DELIVERY_MODE_SKIP_MODULES_TXT: "Can participants skip modules?",
  DELIVERY_MODE_MULTIPLE_SESSION: "MultipleSessionsInd",
  DELIVERY_MODE_MULTIPLE_SESSION_TXT:
    "Can participants click on one session multiple times during a week?",
  DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL: "ElectronicallyXmitPWInd_DL",
  DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL: "ElectronicallyXmitPAInd_DL",
  DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL: "ElectronicallyXmitPWInd_OL",
  DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL: "ElectronicallyXmitPAInd_OL",
  DELIVERY_MODE_WEIGHT_TRANSMITTED_ELECTRONIC_TXT:
    "Is participant weight transmitted electronically?",
  DELIVERY_MODE_PHYSICAL_TRANSMITTED_ELETRONIC_TXT:
    "Is participant physical activity data transmitted electronically?",
  MULTI_STATES_CLASS_PROVD: "MultiStatesClassProvider",
  MULTI_STATE_TERRITORY_LIST: "AppProgramDeliveryStatesIDs",
  DELIVERY_MODE_COMBINATION_INTRO_TXT:
    'Combination with an Online Component delivery is defined as “A yearlong National DPP LCP delivered as a combination of online (non-live) with in-person and/or distance learning (defined above).  Delivering any number of live sessions under this mode will not allow the organization to be considered a live delivery program. All data collected from each delivery mode are consolidated."',
  DELIVERY_MODE_DISTANCE_LEARNING_INTRO_TXT:
    'Distance Learning delivery is defined as “a yearlong National DPP lifestyle change program delivered 100% by trained Lifestyle Coaches via remote classroom or telehealth. The Lifestyle Coach provides live (synchronous) delivery of session content in one location and participants call-in or video-conference from another location.”',
  DELIVERY_MODE_IN_PERSON_INTRO_TXT:
    'In-person is defined as “A yearlong National DPP LCP delivered 100% in-person for all participants by trained Lifestyle Coaches. Participants are physically present in a classroom or classroom-like setting."',
  DELIVERY_MODE_ONLINE_INTRO_TXT: 
    'Online delivery is defined as “A yearlong National DPP LCP delivered online for all participants. One hundred percent (100%) of the program is experienced through the internet via phone, tablet, or laptop in an asynchronous (non-live) classroom where participants are experiencing the content on their own time without a live (non-artificial intelligence [AI]) Lifestyle Coach teaching the content. However, live Lifestyle Coach interaction must be offered to each participant no less than once per week during the first six months and once per month during the second six months. E-mails and text messages can count toward the requirement for live coach interaction as long as there is bi-directional communication between the Lifestyle Coach and participant. Chat bots and AI forums do not count as live Lifestyle Coach interaction.”',
  PARTICIPANT_DELIVERY_MODE_IDS: "ParticipantDeliveryModeIDs",
  INTERACTION_ID: "InteractionID",
  DELIVERY_MODE_DISATANCE: "Distance Learning",
  DELIVERY_MODE_IN_PERSON: "In-person",
  DELIVERY_MODE_ONLINE: "Online",
  TECHNOLOGY_ID: "TechnologyID",
  TECHNOLOGY_OTHER: "OtherTechnology",
  INTERACTION_OTHER: "OtherInteraction",
  US_STATES_OR_TERRITOTIES_OL_VALIDATE: "US_STATES_OR_TERRITOTIES_OL_VALIDATE",
  US_STATES_OR_TERRITOTIES_DL_VALIDATE: "US_STATES_OR_TERRITOTIES_DL_VALIDATE",
  COMBINATION_DELIVERY_MODE_VALIDATE: "COMBINATION_DELIVERY_MODE_VALIDATE",
  DELIVERY_ATTESTATION: "AttestDMODEInd",
  DELIVERY_ATTESTATION_IN_PERSON_LABEL: 
    "I agree to deliver sessions in accordance with section I. Guidelines for Session Delivery of the DPRP Standards, particularly those points that pertain specifically to in-person session delivery.",
  DELIVERY_ATTESTATION_DISTANCE_LEARNING_LABEL: 
    "I agree to deliver sessions in accordance with section I. Guidelines for Session Delivery of the DPRP Standards, particularly those points that pertain specifically to distance learning session delivery.",
  DELIVERY_ATTESTATION_IN_PERSON_DISTANCE_LEARNING_LABEL:
    "I agree to deliver sessions in accordance with section I. Guidelines for Session Delivery of the DPRP Standards, particularly those points that pertain specifically to in-person with a distance learning session delivery.",
  DELIVERY_ATTESTATION_ONLINE_NON_LIVE_LABEL:
    "I agree to deliver sessions in accordance with section I. Guidelines for Session Delivery of the DPRP Standards, particularly those points that pertain specifically to online session delivery.",
  DELIVERY_ATTESTATION_COMBINATION_LABEL:
    "I agree to deliver sessions in accordance with section I. Guidelines for Session Delivery of the DPRP Standards, particularly those points that pertain specifically to combination with an online component session delivery.",
  SOCIAL_VULNERABILITY_INDEX: "SocialVulnerabilityIndexInd",
  SOCIAL_VULNERABILITY_INDEX_LABEL: 
    "My organization is interested in moving immediately into Preliminary recognition by enrolling participants who reside in a county classified as having High Vulnerability by the CDC/ATSDR Social Vulnerability Index.",
  ENROLLMENT_LOCATION: "HasEnrollmentLocation",
  ENROLLMENT_LOCATION_QUESTION: "Are you open to participants in every US State, territory, or freely associated state?",
  ENROLLMENT_LOCATION_VALIDATE_MSG: "Enrollment Location is required",
  DELIVERY_MODE_IN_PERSON_DISTANCE_LEARNING: "In-Person with a Distance Learning Component",
  DELIVERY_MODE_IN_PERSON_DISTANCE_LEARNING_TEXT: 
    'In-person with a Distance Learning Component delivery is defined as “A yearlong National DPP LCP delivered in-person by trained Lifestyle Coaches where participants have the option of attending sessions via remote classroom or telehealth (i.e., the Lifestyle Coach provides live delivery of session content in one location and participants call-in or video-conference from another location)."',

  //type of delivery mode validation text
  DELIVERY_MODE_VALIDATE_MSG: "Delivery mode is required",
  US_STATES_OR_TERRITOTIES_VALIDATE_MSG:
    "US states or territories are required",
  MULTI_STATES_CLASS_PROVD_VALIDATE_MSG: "Required",
  COMBINATION_DELIVERY_MODE_VALIDATE_MSG:
    "You must select more than one delivery mode for Combination Delivery",
  DELIVERY_ATTESTATION_VALIDATE_MSG: "This is a required attestation and must be checked prior to submitting the application. ",

  //secondary contact
  SECONDARY_CONTACT_TITLE: "SecondaryContactTitleId",
  SECONDARY_CONTACT_TITLE_OTHER: "SecondaryContactTitle",
  SECONDARY_CONTACT_FIRST_NAME: "SecondaryContactFirstName",
  SECONDARY_CONTACT_LAST_NAME: "SecondaryContactLastName",
  SECONDARY_CONTACT_MID_NAME: "SecondaryContactMi",
  SECONDARY_CONTACT_SALUTATION: "SecondaryContactSalutation",
  SECONDARY_CONTACT_PHONE: "SecondaryContactPhone",
  SECONDARY_CONTACT_PHONE_AREA_CODE: "SecondaryContactPhone3",
  SECONDARY_CONTACT_PHONE3: "SecondaryContactPhoneAreaCode",
  SECONDARY_CONTACT_PHONE4: "SecondaryContactPhone4",
  SECONDARY_CONTACT_PHONE_EXT: "SecondaryContactPhoneExt",
  SECONDARY_CONTACT_CONTACT_PHONE_EXT: "SecondaryContactExt",
  SECONDARY_CONTACT_CONTACT_EMAIL: "SecondaryContactEmail",
  SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY: "SecondaryContactEmailVerify",
  SECONDARY_CONTACT_EMAIL_UNIQUE: "EmailUniqueSecondary",
  INCLUDE_SECONDARY_CONTACT: "HasSecondaryContact",
  IS_SECONDARY_CONTACT_CHECKED: "isSecondaryContactChecked",
  SECONDARY_CONTACT_INTRO_TEXT:
    "Provide the name of the individual who will be the applicant organization’s Secondary Contact, if applicable. Provide a salutation [e.g., Mr., Mrs., Dr., Ms., Miss, other (please specify); last name; first name; and middle initial]. The Secondary Contact’s name will not be included in the DPRP registry or the CDC Find a Program locator. ",
  SECONDARY_CONTACT_QUESTION_TEXT:
    "Do you have a Secondary Contact? This contact should be different than the Program Coordinator. A contact can only be listed for one role.",
  SECONDARY_CONTACT_NOTE_TEXT:
    "NOTE: Upon approval of your application, you may update this profile to include a Secondary Contact.",
  OTHER_TITLE_REQUIRED: "Other title is required.",
  //data preparer
  DATA_PREPARER_TITLE: "DataContactTitleId",
  DATA_PREPARER_FIRST_NAME: "DataContactFirstName",
  DATA_PREPARER_MID_NAME: "DataContactMi",
  DATA_PREPARER_LAST_NAME: "DataContactLastName",
  DATA_PREPARER_SALUTATION: "DataContactSalutation",
  DATA_PREPARER_PHONE: "DataContactPhone",
  DATA_PREPARER_PHONE_AREA_CODE: "DataContactPhoneAreaCode",
  DATA_PREPARER_PHONE3: "DataContactPhone3",
  DATA_PREPARER_PHONE4: "DataContactPhone4",
  DATA_PREPARER_PHONE_EXT: "DataContactPhoneExt",
  DATA_PREPARER_EMAIL: "DataContactEmail",
  DATA_PREPARER_EMAIL_VERIFY: "DataContactEmailVerify",
  DATA_PREPARER_EMAIL_UNIQUE: "DataPreparerEmailUnique",
  DATA_PREPARER_INTRO_TEXT:
    "Provide the name of the individual who will be the organization’s Data Preparer. If a Data Preparer is not designated, the Program Coordinator or Secondary Contact may submit data for the organization. Provide a salutation [e.g., Mr., Mrs., Dr., Ms., Miss, other (please specify); last name; first name; middle initial; and affiliation). The Data Preparer’s name will not be included in the DPRP registry or the CDC Find a Program locator.",
  DATA_PREPARER_QUESTION_TEXT:
    "Do you have a Data Preparer that is not the Program Coordinator or Secondary Contact? A contact can only be listed for one role. ",
  DATA_PREPARER_NOTE_TEXT:
    "NOTE: Upon approval of your application, you may update this profile to include the designated Data Preparer.",
  INCLUDE_DATA_PREPARER: "HasDataPreparerContact",
  IS_DATA_PREPARER_CHECKED: "iDataPreparerChecked",
  DATA_PREPARER_TYPE: "DATA_PREPARER_TYPE",
  DATA_PREPARER_AFFLICATION: "DataContactAffiliation",
  DATA_PREPARER_PROGRAM_COORDINATOR_TXT: "The Program Coordinator",
  DATA_PREPARER_SECONDARY_CONTACT_TXT: "Secondary Contact",
  DATA_PREPARER_NEW_CONTACT_TXT: "A New Contact",

  //data preparer validation msg
  DATA_PREPARER_AFFLICATION_VALIDATE_MSG: "Affliation is required",

  //Program delivery information,
  SUBMITTED_CURRICULUM_ID: "SubmittedCurriculumID",
  CURRICULUM: "Curriculum",
  CURRICULUM_NAME: "CurriculumName",
  CLASS_TYPE_EMPLOYEES: "ClassTypeEmployees",
  CLASS_TYPE_MEMBERS: "ClassTypeMembers",
  CLASS_TYPE_OTHERS: "ClassTypeOther",
  CLASS_TYPE_PUBLIC: "ClassTypePublic",
  CLASS_TYPE_OTHER: "ClassTypeOther",
  CLASS_TYPE_OTHER_NAME: "ClassTypeOtherName",
  COACH_TRAINING_ENTITY_NAME: "CoachTrainingEntityName",
  COACH_TRAINING_ENTITY_ID: "CoachTrainingEntityTypeId",
  TRAINING_PROVIDER_ID: "TrainingProviderID",
  FILE_UPLOAD: "FileUploadValidate",
  SUPPLEMENT_FILE_ID: "SupplementalFilesIDs",
  SUPLLEMENTAL_FILES_IND: "SupplementalFilesInd",
  SUPPLEMENTAL_FILE_OVERSIZE: "SupplementalFilesCSCInd",
  IS_CURRICULUM: "IsCurriculum",
  OTHER_CURRICULUM_IND: "OtherCurriculumInd",
  IS_OTHER_CURRICULUM_OPTION_ID: "IsOtherCurriculumOptionInd",
  IS_ADDITIONAL_CONTENT_FILE: "HasSupplementalFiles",
  NO_CLASS_TYPE_SELECTED: "NoClassTypeSelected",
  OTHER_CURRICULUM: "Other Curriculum",
  CURRICULUM_FILE_OVER_SIZE: "OtherCurriculumCSCInd",
  FILE_OVER_SIZE: "FileOverSize",
  FILE_SIZE_MB: "FileSizeMB",
  INCLUDE_PROJECTED_START_DATE: "HasProjectedStartDate",
  INCLUDE_PROJECTED_START_DATE_TEXT: "Do you know the projected start date of your first cohort?",
  INCLUDE_PROJECTED_START_DATE_VALIDATE_MSG: "Projected Start Date is required",
  PROJECTED_START_DATE: "ProjectedStartDt",
  PROJECTED_START_DATE_TEXT: "Please provide the projected start date of your program's Session 1:",

  //program delivery information validate text
  CURRICULUM_VALIDATE_MSG: "Curriculum is required",
  CURRICULUM_NAME_VALIDATE_MSG: "Curriculum name is required",
  CURRICULUM_DESCRIPTION_VALIDATE_MSG: "Curriculum description is required",
  CLASS_TYPE_OTHER_NAME_VALIDATE_MSG: "Target audience served is required",
  FILE_UPLOAD_VALIDATE_MSG: "Curriculum file(s) is required",
  FILE_UPLOAD_VALIDATE_MSG_TWO: "Curriculum file(s) does not meet requirements",
  IS_ADDITIONAL_CONTENT_FILE_VALIDATE_MSG: "Additional Content Files(s) is required",
  INCLUDE_ADDITIONAL_CONTENT_FILE_VALIDATE_MSG: "Include addtional content is required",
  PROJECTED_START_DATE_VALIDATE_MSG: "Projected start date cannot be prior to application approval date.",

  //Program delivery information validation text
  CLASS_TYPE_VALIDATE_MSG: "Please select at least one",
  COACH_TRAINING_ENTITY_ID_VALIDATE_MSG: "Please select one",
  COACH_TRAINING_ENTITY_VALIDATE_MSG: "Name of Master Trainer is required",
  COACT_TRAINING_PROVIDER_VALIDATE_MSG: "Name of Training Provider is required",
  //end Program delivery information

  //certification of applicaiton
  ELECTRONIC_SIG_NAME: "ElectronicSignatureName",
  ELECTRONIC_SIG_TITLE: "ElectronicSignatureTitle",
  ELECTRONIC_ORG_NAME: "ElectronicSignatureOrgName",
  ELECTRONIC_SIG_DATE: "ElectronicSignatureDate",
  CERTIFICATION_OF_APPLICATION_TEXT:
  "Submitting the application asserts that the organization has thoroughly reviewed the CDC Diabetes Prevention Recognition Program Standards and Operating Procedures and is voluntarily seeking participation in the CDC recognition program. The organization agrees to comply with all the recognition criteria contained in the DPRP Standards, including the transmission of data to CDC every 6 months from the CDC assigned effective date, for the purpose of program evaluation, continuing recognition, and technical assistance. (Enter the name and title of the authorized representative, the organization name, and date).",
  VERIFIFCATION_AND_SUBMIT_TEXT_ONE:
    "Once an organization’s application has been reviewed and approved, the DPRP will send an email to the organization’s Program Coordinator indicating that the organization has been granted pending recognition. This email will include the unique organization code assigned by the DPRP, the organization’s effective date (which determines the date the organization’s evaluation data are due to the DPRP), and instructions for data submission. Once approved, the organization will be listed on the DPRP Registry. This process takes approximately 15 days. ",
  VERIFIFCATION_AND_SUBMIT_TEXT_TWO:
    "If an organization submits an alternate curriculum for review and approval by CDC, an initial email indicating receipt will be sent. Organizations should allow 4-6 weeks for review and approval of the application and assignment of an organization code. If an alternate curriculum is not approved by CDC, the application will not be approved. CDC will describe in writing the reasons why a curriculum is not approved and allow the organization an opportunity to correct any issues and reapply for recognition once the curriculum is amended. Any questions about an organization’s application status should be directed to the <a target='_blank' href='https://nationaldppcsc.cdc.gov/s/'> National DPP Customer Service Center</a>.",

  //certification validation text
  ELECTRONIC_SIG_NAME_VALIDATE_MSG: "Name is required",
  ELECTRONIC_SIG_TITLE_VALIDATE_MSG: "Title is required",
  ELECTRONIC_ORG_NAME_VALIDATE_MSG: "Organization name is required",

  // FileUpload
  RESET_FILE_UPLOAD_TEXT: "resetFileUploadText",

  //Generic
  APP_SUBMIT_FAIL_TXT:
    "An error occurred, (Application Add) please contact support",
  APP_FILE_UPLOAD_FAIL_TXT:
    "An error occurred, (File Upload) please contact support",
  EMAIL_UNIQUE: "EmailUnique",
  EMAIL_UNIQUE_FAIL_TXT: "Contact Email Address must be unique",
  REQUIRED_QUESTION: "Delivery question is required",
  REQUIRED: "Required",
  ERROR_MSG:
    "Oops! something went wrong; please refresh the page and try again",

  //analytics
  ANALYTICS_PARMS: {
    levels: ["Chronic", "DDT", "diabetes", "NDPP"],
    AzEntry: null,
    channel: "DDT DPRP Application/Registry"
  },

  COLOR_STYLES: {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      fontWeight: 'normal',
    }),
    option: (provided, { isDisabled }) => {
      return {
        ...provided,
        color: 'black !important',
        fontWeight: isDisabled ? 'bold' : 'normal',
        backgroundColor: 'white !important',
        paddingLeft: isDisabled ? '10px' : '30px',
        ':hover': {
          border: isDisabled ? 'none' : 'solid 1px #00695c !important',
        }
      }
    },
    menu: (provided) => {
      return {
        ...provided,
        background: 'transparent'
      }
    }
  }
};
