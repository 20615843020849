import React from "react";
import IntroBanner from "./IntroBanner";
import LandingImageSrc from "../../images/LandingPageSite.png";

const ConfirmationPage = () => (
  <IntroBanner
    confirmationPage={true}
    LandingImageSrc={LandingImageSrc}
    PageTitle="Diabetes Prevention Recognition Program (DPRP) Application Form"
  />
);

export default ConfirmationPage;
