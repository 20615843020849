import React from "react";
import Constant from "../../common/Constant";
import ContactInfo from "../../common/ContactInfo";
import RadioInput from "../../common/RadioInput";

const SecondaryContact = ({
    onChange,
    onKeyDown,
    onValidateBlur,
    onIncludeContactChange,
    onBlurPhoneNumber,
    onSalutationChange,
    isOtherSalutationSecondary = false,
    roleTitles,
    applicationObj,
    errors
}) => {
    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary" onClick={() => { }}>
                Secondary Contact (optional){" "}
            </h2>
            <p className="ml-3 mr-3 mt-3 mb-0">
                <small>{Constant.SECONDARY_CONTACT_INTRO_TEXT}</small>
            </p>
            <div className="card-body">
                <RadioInput
                    onChange={onIncludeContactChange}
                    name={Constant.INCLUDE_SECONDARY_CONTACT}
                    questionText={Constant.SECONDARY_CONTACT_QUESTION_TEXT}
                    noteText={Constant.SECONDARY_CONTACT_NOTE_TEXT}
                    valueOne={true}
                    valueTwo={false}
                    defaultValueOne={true}
                    ariaGroupLabel={Constant.SECONDARY_CONTACT_QUESTION_TEXT}
                    ariaSelected={applicationObj[`${Constant.INCLUDE_SECONDARY_CONTACT}`] ? true : "false"}
                />
                {applicationObj[`${Constant.INCLUDE_SECONDARY_CONTACT}`] && (
                    <div className="form-group" role="group" aria-label="Secondary Contact">
                        <ContactInfo
                            controlTitle="Secondary Contact Name"
                            controlSecondaryTitle="Secondary Contact Information"
                            isSecondaryContact={true}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onValidateBlur={onValidateBlur}
                            onBlurPhoneNumber={onBlurPhoneNumber}
                            onSalutationChange={onSalutationChange}
                            isOtherSalutation={isOtherSalutationSecondary}
                            salutationDdl={Constant.SECONDARY_CONTACT_SALUTATION}
                            salutation={applicationObj[Constant.SECONDARY_CONTACT_SALUTATION]}
                            firstNameControl={Constant.SECONDARY_CONTACT_FIRST_NAME}
                            firstName={applicationObj[Constant.SECONDARY_CONTACT_FIRST_NAME]}
                            midNameControl={Constant.SECONDARY_CONTACT_MID_NAME}
                            midName={applicationObj[Constant.SECONDARY_CONTACT_MID_NAME]}
                            lastNameControl={Constant.SECONDARY_CONTACT_LAST_NAME}
                            lastName={applicationObj[Constant.SECONDARY_CONTACT_LAST_NAME]}
                            applicantTitleControl={Constant.SECONDARY_CONTACT_TITLE}
                            applicantTitleControlOther={Constant.SECONDARY_CONTACT_TITLE_OTHER}
                            applicantTitle={applicationObj[Constant.SECONDARY_CONTACT_TITLE]}
                            applicantTitleOther={applicationObj[Constant.SECONDARY_CONTACT_TITLE_OTHER]}
                            phoneNumberControl={Constant.SECONDARY_CONTACT_PHONE}
                            phoneNumber={applicationObj[Constant.SECONDARY_CONTACT_PHONE]}
                            phoneNumberAreaCode={Constant.SECONDARY_CONTACT_PHONE_AREA_CODE}
                            phoneNumber3={Constant.SECONDARY_CONTACT_PHONE3}
                            phoneNumber4={Constant.SECONDARY_CONTACT_PHONE4}
                            PhoneExtControl={Constant.SECONDARY_CONTACT_PHONE_EXT}
                            PhoneExt={applicationObj[Constant.SECONDARY_CONTACT_PHONE_EXT]}
                            emailAddressControl={Constant.SECONDARY_CONTACT_CONTACT_EMAIL}
                            emailAddress={
                                applicationObj[Constant.SECONDARY_CONTACT_CONTACT_EMAIL]
                            }
                            emailAddressVerifyControl={
                                Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY
                            }
                            emailAddressVerify={
                                applicationObj[Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY]
                            }
                            roleTitles={roleTitles}
                            applicationObj={applicationObj}
                            errors={errors}
                        />
                    </div>
                )}
                {errors[`${Constant.SECONDARY_CONTACT_EMAIL_UNIQUE}`] && (
                    <div className={`alert alert-danger col-4 ml-3`}>
                        {errors[`${Constant.SECONDARY_CONTACT_EMAIL_UNIQUE}`]}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecondaryContact;
