import React, { useState } from "react";
import Constant from "../../common/Constant";
import ReCAPTCHA from "react-google-recaptcha";
import { getFormInfo } from "../../api/api";
import { useEffect } from "react";

const VerificationAndSubmit = ({
    recaptchaRef
}) => {
    const [recaptchaVerify, setRecaptchaVerify] = useState(false);

    useEffect(function () {
        getFormInfo().then(({ data }) => {
            if(data.environment === "QA"){
                setRecaptchaVerify(true);
            }
        });
    }, []); // eslint-disable-line 

    //recaptcha verification
    function onChange(value) {
        if (value.length > 0) {
            setRecaptchaVerify(true);
        } else {
            setRecaptchaVerify(false);
        }
    }

    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary">
                Verification & Submit Application
            </h2>
            <div className="card-body">
                <p>{Constant.VERIFIFCATION_AND_SUBMIT_TEXT_ONE}</p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: Constant.VERIFIFCATION_AND_SUBMIT_TEXT_TWO
                    }}
                />
                <ReCAPTCHA
                    sitekey="6LdqtwMqAAAAAPxO1DlltI32bS9rQE-19qdbi3-D"
                    onChange={onChange}
                    size="invisible"
                    ref={recaptchaRef}
                />

                <button
                    id="btnSubmitApplicaiton"
                    className="btn btn-primary"
                    //disabled={!recaptchaVerify}
                >
                    Submit Application
                </button>
            </div>
        </div>
    );
};

export default VerificationAndSubmit;
