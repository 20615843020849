import axios from "axios";
import { getTodaysDate } from "../common/Util";

//GET: state lookup
export function getStateLku() {
    return axios.get("api/States/GetStatesWithTerritories");
}

//GET: Title role Lookup
export function getRoleTitlesLku() {
    return axios.get("api/RoleTitlesLookUp");
}

//GET: organization categories lookup
function getorganizationCategoriesLku() {
    return axios.get("api/OrganizationCategoriesLookUp");
}

//GET: program delivery mode lookup, single
function getProgramDeliveryModeLku() {
    return axios.get("api/ProgramDeliveryModesLookUp");
}

//GET: program delivery mode, combination
function getProgramParticipantDeliveryModeLku() {
    return axios.get("api/ParticipantDeliveryMode");
}

//GET: class type lookup
function getClassTypeLku() {
    return axios.get("api/ClassTypesLookUp");
}

//GET:  curriculums look up data
function getCurriculumsLku() {
    return axios.get("api/CurriculumsLookUp");
}

//GET:  coaching training look up data
function getCoachTrainingEntitryTypesLku() {
    return axios.get("api/CoachTrainingEntityTypesLookUp");
}

//GET: interactionMechanism
function getInteractionMechanism() {
    return axios.get("api/InteractionMechanism");
}

//GET: Technology
function getTechnologyLookUp() {
    return axios.get("api/TechnologyLookUp ");
}

//GET: form OMB number and expiration date
export function getFormInfo() {
    return axios.get("api/FormInfo/GetFormConfigInfo/");
}

//GET: training provider
export function getTrainingProviderLku(providerId = 1) {
    return axios
        .get("api/TrainingProvider/" + providerId);
}

//get all 6 look up and form approval info data and return them in one array, the UI will handle the data distribution
export function getAllLookUpData() {
    return Promise.all([
        getStateLku(),
        getRoleTitlesLku(),
        getorganizationCategoriesLku(),
        getProgramDeliveryModeLku(),
        getProgramParticipantDeliveryModeLku(),
        getClassTypeLku(),
        getCurriculumsLku(),
        getCoachTrainingEntitryTypesLku(),
        getTrainingProviderLku(),
        getFormInfo(),
        getInteractionMechanism(),
        getTechnologyLookUp()
    ])
        .then(response => {
            //the harcoded array is use to restructure the data[key] to improve readability in the UI
            let lookupTypeArray = [
                "stateLku",
                "roleTitlesLku",
                "organizationLku",
                "programDeliveryLku",
                "programParticipantLku",
                "classTypeLku",
                "curriculumnLku",
                "coachTrainingLku",
                "trainingProviderLku",
                "formApprovalInfo",
                "InteractionMechanism",
                "TechnologyLookUp"
            ];
            //remap the response data structure for better readbility when accessesing from the ui
            const remapResult = response.reduce((acc, item, index) => {
                acc[lookupTypeArray[index]] = { data: item.data };
                return acc;
            }, {});
            return remapResult;
        })
        .catch(error => {
            //handle error
            return error;
        });
}

//POST: send application form object
export function saveApplicationOjb(applicationObj) {
    //console.log("POST: Application Object:", applicationObj);
    return axios
        .post("api/Applications", applicationObj)
        .then(response => {
            //console.log("POST: Save Application Object Success: ",response);
            return response;
        })
        .catch(err => {
            //should log the error here
            console.error("POST: Save Application Object Failed", err)
            return { error: err.message };
        });
}

//POST:  send address object
export function verifyAddress(addressObj) {
    //console.log("Address to verify: ", addressObj);
    return axios
        .post("api/AddressValidate/ValidateAddress", addressObj)
        .then(response => {
            //console.log("USPS Returned Verified Adress: ", response.data);
            return response.data;
        })
        .catch(err => {
            //should log the error here
            return err;
        });
}

//POST: file upload
export function uploadFileToServer(fileData) {
    return axios
        .post("api/FileUpload", fileData)
        .then(response => {
            //console.log("File Upload Response: ", response);
            return response;
        })
        .catch(err => {
            //handle error
            return err.message;
        });
}

//GET: registry data lookup
export function getRegistryLku() {
    return axios
        .get("api/RecognizedRegistries")
        .then(response => {
            //console.log(response.data)
            return response.data;
        })
        .catch(err => {
            //handle error
            return err;
        });
}

//GET: registry data by id lookup
export function getRegistryByIdLku(registryId) {
    return axios
        .get("api/RecognizedRegistries?" + registryId)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            //handle error
            return err;
        });
}

//method to generate link for download
function downloadExcel(response) {
    //creating the blob and then download the excel file
    const url = window.URL.createObjectURL(new Blob([response.data])),
        { headers } = response;

    var contentDisposition = headers["content-disposition"];
    var filename = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        filename + ".xlsx"
    );
    document.body.appendChild(link);
    link.click();
}


//POST: download excel file
export function downloadRegistry() {
    return axios
        .post("api/RecognizedRegistryToExcels/DownloadFile", null, {
            headers: {
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            },
            responseType: "arraybuffer"
        })
        .then(response => {
            downloadExcel(response);
        }).catch(err => {
            //handle error
            return err;
        });;
}

//POST: download CMS excel file
export function downloadCMSRegistry() {
    return axios
        .post("/api/CMSRecognizedRegistries/DownloadFile", null, {
            headers: {
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            },
            responseType: "arraybuffer"
        })
        .then(response => {
            downloadExcel(response);
        }).catch(err => {
            //handle error
            return err;
        });;
}

//use this to load as default in webhook to test submit, tweak as needed
export const defaultTestObj = {
    "AcknowledgeSOPInd": true,
    "AcknowledgeOCAInd": true,
    "Reapply": false,
    "PreviousAssignedCode": 0,
    "OrgPhoneExt": "",
    "PhysicalAddress2": "",
    "PhysicalZip4": "",
    "webAddress": "",
    "ClassTypeOtherName": "",
    "MultiStatesClassProvider": "false",
    "AppProgramDeliveryStatesIDs": [],
    "ParticipantDeliveryModeIDs": [],
    "NationalOnLineProvider": false,
    "HasSupplementalFiles": false,
    "AddressValidatedInd": false,
    "HasSecondaryContact": false,
    "HasDataPreparerContact": false,
    "DATA_PREPARER_TYPE": "A New Contact",
    "DataContactAffiliation": "",
    "IsCurriculum": "false",
    "FileSizeMB": 0,
    "OtherCurriculumInd": false,
    "IsOtherCurriculumOptionInd": false,
    "SupplementalFilesCSCInd": false,
    "OtherCurriculumCSCInd": false,
    "SupplementalFilesInd": false,
    "SupplementalFilesIDs": [],
    "In-person": "false",
    "Online": "false",
    "ModulesAccessInd": "false",
    "InteractionID": null,
    "OtherInteraction": "",
    "TechnologyID": null,
    "OtherTechnology": "",
    "ContactPhoneExt": "",
    "ContactNameSalutation": "",
    "ContactNameMi": "",
    "SecondaryContactSalutation": "",
    "SecondaryContactTitle": "",
    "SecondaryContactFirstName": "",
    "SecondaryContactMi": "",
    "SecondaryContactLastName": "",
    "SecondaryContactPhone": "",
    "SecondaryContactPhone3": "",
    "SecondaryContactPhoneAreaCode": "",
    "SecondaryContactPhone4": "",
    "SecondaryContactPhoneExt": "",
    "SecondaryContactEmail": "",
    "DataContactSalutation": "",
    "DataTitle": "",
    "DataContactFirstName": "",
    "DataContactMi": "",
    "DataContactLastName": "",
    "DataContactPhoneAreaCode": "",
    "DataContactPhone3": "",
    "DataContactPhone4": "",
    "DataContactPhoneExt": "",
    "DataContactEmail": "",
    "DeliveryModeId": 1,
    "SubmittedCurriculumID": null,
    "Curriculum": "2021 PreventT2 - English",
    "CoachTrainingEntityTypeId": 1,
    "TrainingProviderID": "5",
    "orgName": "local debug testing",
    "PhysicalAddress": "asdf",
    "PhysicalCity": "asdf",
    "PhysicalStateId": "9",
    "State": "FL",
    "PhysicalZip5": "12312",
    "OrgPhoneNumber": "(123) 123 - 1231",
    "OrgPhoneAreaCode": "123",
    "OrgPhone3": "123",
    "OrgPhone4": "1231",
    "OrganizationCategoryId": "7",
    "ContactNameFirstName": "asdf",
    "ContactNameLastName": "asdf",
    "ApplicantTitle": "asf",
    "ContactPhone": "(123) 121 - 2312",
    "ContactPhoneAreaCode": "123",
    "ContactPhone3": "121",
    "ContactPhone4": "2312",
    "ContactEmail": "asdf@mail.com",
    "ContactEmailVerify": "asdf@mail.com",
    "SecondaryContactEmailVerify": "",
    "DataContactPhone": "",
    "DataContactEmailVerify": "",
    "undefined": "",
    "ClassTypePublic": true,
    "ElectronicSignatureName": "asdf",
    "ElectronicSignatureTitle": "asdf",
    "ElectronicSignatureOrgName": "asdf"
};
