import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import Modal from "react-modal";
import { BsQuestionCircle } from "react-icons/bs";
export default function AddressTips({ setShowAddressTips }) {
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      border: "none",
      background: "none",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };

  // Make sure to bind modal to your appElement, it will be in your public/index.html file )
  Modal.setAppElement("#root");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <button
        type="button"
        className="mt-3"
        onClick={openModal}
        style={{ border: "none", background: "none" }}
      >
        <BsQuestionCircle style={{ fontSize: "25px" }} /> Address Tips
      </button>
      {modalIsOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "#000000",
            opacity: 0.2,
            zIndex: 0
          }}
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="card col-5 p-0 m-auto">
          <div className="card-header bg-primary">
            <span className="h4 pull-left">Organization Address </span>
            <button
              type="button"
              className="pull-right"
              style={{ border: "none", background: "none" }}
            >
              <BsX
                className="pull-right"
                style={{ fontSize: "30px" }}
                onClick={() => closeModal(false)}
              />
            </button>
          </div>
          <div className="card-body">
            <span style={{ fontWeight: "bold" }}>Address Tips</span>
            <div id="updPanelShippingAddressTips">
              <div style={{ padding: "0px 20px 0px 20px" }}>
                <ul>
                  <li>
                    Line 1 should be a street address only (no P.O. box, city,
                    state, etc.)
                  </li>
                  <li>
                    Line 2 should be an apartment, suite, unit, or floor number.
                    For example, APT 1 (or leave blank.)
                  </li>
                  <li>
                    Enter information in the appropriate fields. For example, do
                    not enter city and state information in the "Address Line 2"
                    field; use the provided city and state fields instead.
                  </li>
                  <li>Avoid using accent marks or non-English characters.</li>
                  <li>
                    Do not use special characters. See the special character
                    list below.
                  </li>
                </ul>
                <span style={{ fontWeight: "bold" }}>
                  Common U.S. Postal Service abbreviations that might help:
                </span>
                <br />
                <br />
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="lstabbreviationsAddress">
                      (Use the applicable abbreviation in Address Lines 1 or 2)
                    </label>
                    <br />
                    <select
                      size="7"
                      name="ctl00$contentArea2$ucShippingInfo1$lstabbreviationsAddress"
                      id="lstabbreviationsAddress"
                    >
                      <option value="Apartment: APT">Apartment: APT</option>
                      <option value="Avenue: AVE">Avenue: AVE</option>
                      <option value="Beach: BCH">Beach: BCH</option>
                      <option value="Boulevard: BLVD">Boulevard: BLVD</option>
                      <option value="Building: BLDG">Building: BLDG</option>
                      <option value="Canyon: CYN">Canyon: CYN</option>
                      <option value="Center: CTR">Center: CTR</option>
                      <option value="Circle: CIR">Circle: CIR</option>
                      <option value="Court: CT">Court: CT</option>
                      <option value="Crescent: CRES">Crescent: CRES</option>
                      <option value="Crossing: XING">Crossing: XING</option>
                      <option value="Department: DEPT">Department: DEPT</option>
                      <option value="Drive: DR">Drive: DR</option>
                      <option value="Expressway: EXPY">Expressway: EXPY</option>
                      <option value="Falls: FLS">Falls: FLS</option>
                      <option value="Field: FLD">Field: FLD</option>
                      <option value="Floor: FL">Floor: FL</option>
                      <option value="Fort: FT">Fort: FT</option>
                      <option value="Gardens: GDNS">Gardens: GDNS</option>
                      <option value="Harbor: HBR">Harbor: HBR</option>
                      <option value="Heights: HTS">Heights: HTS</option>
                      <option value="Highway: HWY">Highway: HWY</option>
                      <option value="Hills: HLS">Hills: HLS</option>
                      <option value="Island: IS">Island: IS</option>
                      <option value="Junction: JCT">Junction: JCT</option>
                      <option value="Lake: LK">Lake: LK</option>
                      <option value="Landing: LDG">Landing: LDG</option>
                      <option value="Larie: LN">Larie: LN</option>
                      <option value="Lodge: LDG">Lodge: LDG</option>
                      <option value="Mount: MT">Mount: MT</option>
                      <option value="Mountain: MTN">Mountain: MTN</option>
                      <option value="Office: OFC">Office: OFC</option>
                      <option value="Parkway: PKWY">Parkway: PKWY</option>
                      <option value="Penthouse: PH">Penthouse: PH</option>
                      <option value="Plaza: PLZ">Plaza: PLZ</option>
                      <option value="Point: PT">Point: PT</option>
                      <option value="Post Office Box: P.O. Box">
                        Post Office Box: P.O. Box
                      </option>
                      <option value="Road: RD">Road: RD</option>
                      <option value="Room: RM">Room: RM</option>
                      <option value="Route: RTE">Route: RTE</option>
                      <option value="Square: SQ">Square: SQ</option>
                      <option value="Station: STA">Station: STA</option>
                      <option value="Street: ST">Street: ST</option>
                      <option value="Suite: STE">Suite: STE</option>
                      <option value="Terrace: TER">Terrace: TER</option>
                      <option value="Turnpike: TPKE">Turnpike: TPKE</option>
                      <option value="Valley: VLY">Valley: VLY</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <label
                      htmlFor="lstSpecialCharacters"
                      style={{ fontWeight: "bold" }}
                    >
                      Special Characters
                    </label>
                    <br />
                    <select
                      size="7"
                      name="ctl00$contentArea2$ucShippingInfo1$lstSpecialCharacters"
                      id="lstSpecialCharacters"
                    >
                      <option value="&nbsp;&nbsp; !">&nbsp;&nbsp; !</option>
                      <option value="&quot; - &quot; (quotation mark)">
                        " - " (quotation mark)
                      </option>
                      <option value="&nbsp; #">&nbsp; #</option>
                      <option value="&nbsp; $">&nbsp; $</option>
                      <option value="&nbsp; %">&nbsp; %</option>
                      <option value="&nbsp; &amp;">&nbsp; &amp;</option>
                      <option value="&nbsp;&nbsp; -">&nbsp;&nbsp; -</option>
                      <option value="&nbsp;&nbsp; (">&nbsp;&nbsp; (</option>
                      <option value="&nbsp;&nbsp; )">&nbsp;&nbsp; )</option>
                      <option value="&nbsp;&nbsp; *">&nbsp;&nbsp; *</option>
                      <option value="&nbsp;&nbsp; +">&nbsp;&nbsp; +</option>
                      <option value="&nbsp; &nbsp; , (comma)">
                        &nbsp; &nbsp; , (comma)
                      </option>
                      <option value="&nbsp;&nbsp; - -">&nbsp;&nbsp; - -</option>
                      <option value="&nbsp; &nbsp; . (dot or period)">
                        &nbsp; &nbsp; . (dot or period)
                      </option>
                      <option value="&nbsp; &nbsp; / (forward slash)">
                        &nbsp; &nbsp; / (forward slash)
                      </option>
                      <option value="&nbsp; &nbsp; :">&nbsp; &nbsp; :</option>
                      <option value="&nbsp; &nbsp; ;">&nbsp; &nbsp; ;</option>
                      <option value="&nbsp; &nbsp; < ">
                        &nbsp; &nbsp; &lt;{" "}
                      </option>
                      <option value="&nbsp; &nbsp; = ">&nbsp; &nbsp; = </option>
                      <option value="&nbsp; &nbsp; >">
                        &nbsp; &nbsp; &gt;
                      </option>
                      <option value="&nbsp;&nbsp; ? ">&nbsp;&nbsp; ? </option>
                      <option value="&nbsp;&nbsp; @ ">&nbsp;&nbsp; @ </option>
                      <option value="&nbsp; &nbsp; [ ">&nbsp; &nbsp; [ </option>
                      <option value="&nbsp; &nbsp; \ ">&nbsp; &nbsp; \ </option>
                      <option value="&nbsp; &nbsp; ] ">&nbsp; &nbsp; ] </option>
                      <option value="&nbsp; &nbsp; ^ ">&nbsp; &nbsp; ^ </option>
                      <option value="&nbsp; &nbsp; _ (underscore)">
                        &nbsp; &nbsp; _ (underscore)
                      </option>
                      <option value="&nbsp; &nbsp; `&nbsp; (grave, backtick)">
                        &nbsp; &nbsp; `&nbsp; (grave, backtick)
                      </option>
                      <option value="&nbsp; &nbsp; { ">
                        &nbsp; &nbsp; {`{ `}
                      </option>
                      <option value="&nbsp; &nbsp; | ">&nbsp; &nbsp; | </option>
                      <option value="&nbsp; &nbsp; } ">
                        &nbsp; &nbsp; {`} `}
                      </option>
                      <option value="&nbsp; &nbsp; ~ ">&nbsp; &nbsp; ~ </option>
                      <option value="&nbsp; &nbsp;&nbsp; ´&nbsp; (acute)">
                        &nbsp; &nbsp;&nbsp; ´&nbsp; (acute)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-primary" onClick={closeModal}>
              {" "}
              {`< `}Back
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
