import React from "react";

const ErrorList = ({ errors }) => {
  return (
    <>
      <div>
        The following are required:
        <ul>
          {Object.values(errors).map(function(keyValue, keyIndex) {
            return <li key={keyIndex + "errorList"}>{keyValue}</li>;
          })}
        </ul>
      </div>
    </>
  );
};

export default ErrorList;
