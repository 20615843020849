import React from "react";
import Constant from "../../common/Constant";
import ContactInfo from "../../common/ContactInfo";

const ProgramCoordinator = ({
  onChange,
  onKeyDown,
  roleTitles,
  onValidateBlur,
  onBlurPhoneNumber,
  onSalutationChange,
  isOtherSalutation = false,
  applicationObj,
  errors
}) => {
  return (
    <div className="card">
      <h2 className="h4 card-header bg-primary">Program Coordinator</h2>

      <div className="card-body">
        <p>
          <small>
          Provide the name of the individual who will be the applicant organization’s Program Coordinator. Provide a
          salutation [e.g., Mr., Mrs., Dr., Ms., Miss, other (please specify); last name; first name; and middle
          initial]. The Program Coordinator’s name will not be included in the DPRP Registry or the CDC Find a Program locator.
          </small>
        </p>
        <div className="form-group">
          <ContactInfo
            controlTitle="Program Coordinator Name"
            controlSecondaryTitle="Program Coordinator Contact Information"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onValidateBlur={onValidateBlur}
            onBlurPhoneNumber={onBlurPhoneNumber}
            onSalutationChange={onSalutationChange}
            isOtherSalutation={isOtherSalutation}
            salutationDdl={Constant.CONTACT_SALUTATION}
            salutation={applicationObj[Constant.CONTACT_SALUTATION]}
            firstNameControl={Constant.CONTACT_FIRST_NAME}
            firstName={applicationObj[Constant.CONTACT_FIRST_NAME]}
            midNameControl={Constant.CONTACT_MID_NAME}
            midName={applicationObj[Constant.CONTACT_MID_NAME]}
            lastNameControl={Constant.CONTACT_LAST_NAME}
            lastName={applicationObj[Constant.CONTACT_LAST_NAME]}
            applicantTitleControl={Constant.CONTACT_TITLE}
            applicantTitleControlOther={Constant.CONTACT_TITLE_OTHER}
            applicantTitle={applicationObj[Constant.CONTACT_TITLE]}
            applicantTitleOther={applicationObj[Constant.CONTACT_TITLE_OTHER]}
            phoneNumberControl={Constant.CONTACT_PHONE}
            phoneNumber={applicationObj[Constant.CONTACT_PHONE]}
            phoneNumberAreaCode={Constant.CONTACT_PHONE_AREA_CODE}
            phoneNumber3={Constant.CONTACT_PHONE3}
            phoneNumber4={Constant.CONTACT_PHONE4}
            PhoneExtControl={Constant.CONTACT_PHONE_EXT}
            PhoneExt={applicationObj[Constant.CONTACT_PHONE_EXT]}
            emailAddressControl={Constant.CONTACT_EMAIL}
            emailAddress={applicationObj[Constant.CONTACT_EMAIL]}
            emailAddressVerifyControl={Constant.CONTACT_EMAIL_VERIFY}
            emailAddressVerify={applicationObj[Constant.CONTACT_EMAIL_VERIFY]}
            applicationObj={applicationObj}
            primaryContactInfo={true}
            roleTitles={roleTitles}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramCoordinator;
