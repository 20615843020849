import React from "react";
import Constant from "../../common/Constant";
import RequiredSpanText from "../../common/RequiredSpanText";

const DeliveryAttestation = ({
  name,
  title,
  applicationObj,
  change,
  errors,
}) => {
  return (
    <>
      <div className="form-group mt-3 mb-0">
        {/* <p><b>Delivery Attestation</b></p> */}
        <div className="font-weight-bold">
          <RequiredSpanText text={'Delivery Attestation'} />
        </div>
        <div className="custom-control">
            <input
                type="checkbox"
                className="big-checkbox"
                onChange={change}
                name={Constant.DELIVERY_ATTESTATION}
                value={applicationObj[`${Constant.DELIVERY_ATTESTATION}`]}
                id={Constant.DELIVERY_ATTESTATION}
            />
            <label
                className="pl-3"
                htmlFor={name}
            >
              {title}
            </label>
            {errors[`${Constant.DELIVERY_ATTESTATION}`] && (
                <div className={`alert alert-danger col-8`}>
                    {errors[`${Constant.DELIVERY_ATTESTATION}`]}
                </div>
            )}
        </div>
      </div>
    </>
  );
};

export default DeliveryAttestation;