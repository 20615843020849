import React from 'react';

const PageNav = (props) => {
  const {
    changePageNumber,
    pageNumber,
    maxRows,
    pageSize,
    firstRow,
    lastRow,
    className,
  } = props;

  const pageMax = Math.floor(maxRows / pageSize) + 1;
  const pageNavClick = (e, targ) => {
    e.preventDefault();

    switch (targ) {
      case 'First':
        changePageNumber(1);
        break;
      case 'Previous':
        changePageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber);
        break;
      case 'Next':
        changePageNumber(pageNumber < pageSize ? pageNumber + 1 : pageNumber);
        break;
      case 'Last':
        changePageNumber(pageSize);
        break;
      default:
        break;
    }
  };

  return (
    <div className={'d-flex justify-content-center ' + className}>
      {/* <div className="text-right mt-2 mr-2">
        <span>{`${
          maxRows === 0 ? 0 : firstRow + 1
        } - ${lastRow} of ${maxRows} files`}</span>
      </div> */}
      <div>
        <nav
          aria-label="Pagination nav"
          className="d-flex justify-content-center"
        >
          <ul style={{ marginBottom: '2px' }} className="pagination">
            <li className="page-item" onClick={(e) => pageNavClick(e, 'First')}>
              <button
                className="page-link page-link-nav"
                style={{ color: '#00695c' }}
                aria-label="First"
                disabled={pageNumber <= 1}
                aria-disabled={pageNumber == 1 ? "true" : "false"}
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <li
              className="page-item"
              onClick={(e) => pageNavClick(e, 'Previous')}
            >
              <button
                className="page-link page-link-nav"
                style={{ color: '#00695c' }}
                aria-label="Previous"
                disabled={pageNumber <= 1}
                aria-disabled={pageNumber == 1 ? "true" : "false"}
              >
                <span aria-hidden="true">&lsaquo;</span>
              </button>
            </li>
            <li className="page-item active">
              <p
                className="page-link mb-0 page-link-nav-non-interactive"
                tabIndex={'-1'}
                style={{
                  backgroundColor: '#00695c',
                  borderColor: '#00695c',
                  zIndex: 'unset',
                }}
              >
                {pageNumber}
              </p>
            </li>
            <li className="page-item" onClick={(e) => pageNavClick(e, 'Next')}>
              <button
                className="page-link page-link-nav"
                style={{ color: '#00695c' }}
                aria-label="Next"
                disabled={pageNumber >= pageSize}
                aria-disabled={pageNumber >= pageSize ? "true" : "false"}
              >
                <span aria-hidden="true">&rsaquo;</span>
              </button>
            </li>
            <li className="page-item" onClick={(e) => pageNavClick(e, 'Last')}>
              <button
                className="page-link page-link-nav"
                style={{ color: '#00695c' }}
                aria-label="Last"
                disabled={pageNumber == pageSize}
                aria-disabled={pageNumber == pageSize ? "true" : "false"}
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default PageNav;
