import React from "react";
import Constant from "../../common/Constant";

const SocialVulnerabilityIndex = ({
  title,
  change,
  applicationObj
}) => {
  return (
    <>
      <div className="form-group mt-3 mb-0">
        <p><b>Social Vulnerability Index</b></p>
        <p className="ml-3">
          <small>
            Your organization will be considered for immediate advancement to Preliminary recognition if you are serving
            a population that resides in a county classified as having “High” vulnerability according to the
            <a target="_blank" className="pl-1" href="https://www.atsdr.cdc.gov/placeandhealth/svi/interactive_map.html">
              CDC/ATSDR Social Vulnerability Index (SVI)
            </a>
            . Social vulnerability refers to the potential negative effects on communities
            caused by external stresses on human health. Such stresses include natural or human-caused disasters, or disease
            outbreaks. Reducing social vulnerability can decrease both human suffering and economic loss.
          </small>
        </p>
        <p className="ml-3">
          <small>
            To determine the SVI of the community/communities you’re serving, go to the website and enter the zip code or
            county. Themes in SVI include socioeconomic status, household characteristics, racial and ethnic minority
            status, and housing type and transportation.
          </small>
        </p>
        <div className="custom-control">
            <input
                type="checkbox"
                className="big-checkbox"
                onChange={change}
                name={Constant.SOCIAL_VULNERABILITY_INDEX}
                value={applicationObj[`${Constant.SOCIAL_VULNERABILITY_INDEX}`]}
                id={Constant.SOCIAL_VULNERABILITY_INDEX}
            />
            <label
                className="pl-3"
                htmlFor={Constant.SOCIAL_VULNERABILITY_INDEX}
            >
              {title}
            </label>
        </div>
      </div>
    </>
  );
};

export default SocialVulnerabilityIndex;