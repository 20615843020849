import React from "react";
import ClassTypes from "../ui/ClassTypes";
import CoachTrainingEntityTypes from "../ui/CoachTrainingEntityTypes";
import Curriculums from "../ui/Curriculums";
import RadioInput from "../../common/RadioInput";
import TextInput from "../../common/TextInput";
import RequiredSpanText from "../../common/RequiredSpanText";
import Constant from "../../common/Constant";

const ProgramDeliveryInfo = ({
    onChange,
    onValidateBlur,
    onChangeCurriculum,
    onChangeUpload,
    onCheckBoxChange,
    onIncludeProjectedStartDateChange,
    projectedStartDate,
    projectedStartDateText,
    isCheckedOtherCurriculum,
    isUploadDisabled,
    applicationObj,
    totalFileSize,
    includeProjectedStartDate,
    includeProjectedStartDateText,
    classTypes,
    curriculums,
    coachTrainingEntityTypes,
    trainingProvider,
    errors
}) => {
    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary">Program Delivery Information</h2>
            <div className="card-body">
                <ClassTypes
                    classType={classTypes}
                    onChange={onChange}
                    onValidateBlur={onValidateBlur}
                    onCheckBoxChange={onCheckBoxChange}
                    applicationObj={applicationObj}
                    errors={errors}
                />
                <CoachTrainingEntityTypes
                    applicationObj={applicationObj}
                    onChange={onChange}
                    onValidateBlur={onValidateBlur}
                    coachTrainingEntityType={coachTrainingEntityTypes}
                    onCheckBoxChange={onCheckBoxChange}
                    trainingProvider={trainingProvider}
                    errors={errors}
                />
                <Curriculums
                    curriculum={curriculums}
                    onChange={onChange}
                    onChangeCurriculum={onChangeCurriculum}
                    onChangeUpload={onChangeUpload}
                    isCheckedOtherCurriculum={isCheckedOtherCurriculum}
                    totalFileSize={totalFileSize}
                    applicationObj={applicationObj}
                    isUploadDisabled={isUploadDisabled}
                    errors={errors}
                />
                <fieldset>
                    <legend className="h5 font-weight-bold mt-3">
                        Projected Start Date<RequiredSpanText />
                    </legend>
                    <div className="ml-3">
                        <RadioInput
                            onChange={onIncludeProjectedStartDateChange}
                            name={includeProjectedStartDate}
                            questionText={includeProjectedStartDateText}
                            valueOne={true}
                            valueTwo={false}
                            defaultValueOne={false}
                            ariaGroupLabel={"Projected Start Date"}
                            ariaSelected={""}
                            error={errors[`${includeProjectedStartDate}`]}
                        />
                    </div>
                    {applicationObj[`${includeProjectedStartDate}`] == true && (
                    <div className="mb-4 ml-3">
                        <div>
                            <TextInput
                                name={projectedStartDate}
                                isDate={true}
                                minStartDate={`${new Date().toJSON().slice(0, 10)}`}
                                onChange={onChange}
                                inputAdditionalStyling={'col-4'}
                                errorAdditionalStyling={'col-4'}
                                labelText={projectedStartDateText}
                                requiredLabel={true}
                                error={errors[`${projectedStartDate}`]}
                            />
                            <small>
                                NOTE: Please contact the
                                <a className="ml-1 mr-1" href="https://nationaldppcsc.cdc.gov/s/" target="_blank">
                                    National DPP Customer Service Center (CSC)
                                </a>
                                if your organization's Projected Start Date has already passed
                            </small>
                        </div>
                    </div>
                    )}
                </fieldset>
            </div>
        </div>
    );
};

export default ProgramDeliveryInfo;
