import React from "react";
import { Helmet } from "react-helmet";
import { doAnalyticsWithTimeout } from "../../common/analytics";
import Constant from "../../common/Constant";
import { Layout } from "../layout/Layout";

const IntroBanner = ({
    LandingImageSrc = "",
    formApprovalInfo = false,
    PageTitle = "",
    ApplicationFormPage = false,
    RegistryPage = false,
    confirmationPage = false,
}) => {
    return (
        <>
            {!confirmationPage &&
                <div className="intro">
                    <h1>{PageTitle}</h1>
                    <div className="row mt-3">
                        <div className="col-9">
                            {ApplicationFormPage && (
                                <p>
                                    Public reporting burden of this collection of information is
                                    estimated to average 60 minutes per response, including the time
                                    for reviewing instructions, searching existing data sources,
                                    gathering and maintaining data needed, and completing and
                                    reviewing the collection of information. An agency may not conduct
                                    or sponsor, and a person is not required to respond to a
                                    collection of information unless it displays a currently valid OMB
                                    control number. Send comments regarding this burden estimate or
                                    any other aspect of this collection of information, including
                                    suggestions for reducing this burden to CDC Reports Clearance
                                    Officer; 1600 Clifton Road NE, MS D-74, Atlanta, Georgia 30333;
                                    ATTN: PRA (0920-0909).
                                    <br /><br />
                                </p>
                            )}
                            {RegistryPage && (
                                <p>
                                    The National Registry of Recognized Diabetes Prevention Programs
                                    lists contact information for all CDC-recognized organizations
                                    that deliver evidence-based type 2 diabetes prevention programs in
                                    communities across the United States. All of these programs have
                                    agreed to use a CDC-approved curriculum that meets the duration,
                                    intensity, and reporting requirements described in the{" "}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="DPRP Standards"
                                        className="italicLink tp-link-policy nonHtml noDecoration plugin"
                                        href="https://www.cdc.gov/diabetes-prevention/media/pdfs/legacy/dprp-standards.pdf"
                                    >
                                        <span className="tp-label mr-2">DPRP Standards</span>
                                        <span className="x32 fill-p cdc-icon-pdf" />
                                    </a>{" "}
                                    . Full recognition means that a program has demonstrated
                                    effectiveness by achieving all of the performance criteria
                                    detailed in the DPRP Standards.
                                </p>
                            )}
                        </div>
                        <div className="col-3 text-right">
                            <a href="https://nationaldppcsc.cdc.gov/s/" role="link" title="Link to the National Diabetes Prevention Program Customer Service Center">
                                <img alt="Link to the National Diabetes Prevention Program Customer Service Center" src={LandingImageSrc} />
                                <span className="sr-only">customer service center</span>
                            </a>
                        </div>
                        <p className="small col-12 text-right">
                            {!confirmationPage && formApprovalInfo && "Form Approved"}
                            <br />
                            {formApprovalInfo.dprP_OMB} <br />
                            {formApprovalInfo.dprP_Exp_Date}
                        </p>
                    </div>
                </div>
            }{confirmationPage && (
                <Layout>
                    <Helmet>
                        <title>Diabetes Prevention Recognition Program Application | Confirmation | CDC</title>
                    </Helmet>
                    {doAnalyticsWithTimeout(Constant.ANALYTICS_PARMS)}
                    {confirmationPage && (
                        <div className="row col-12">
                            <div className="col-9">
                                <span className="h6">Thank you</span>
                                <p>
                                    Thank you for your application to the Diabetes Prevention
                                    Recognition Program (DPRP). Please check your email for a
                                    message from DPRP confirming submission of your application. If
                                    you do not see this confirmation in your inbox, please check
                                    your spam or junk mail folder before contacting us.
                                </p>

                                <span className="h6">Change Instructions</span>
                                <p>
                                    If you wish to make a change in your application (such as
                                    updating contact information or correcting an error), or wish to
                                    withdraw your application, please contact us through the{" "}
                                    <a href="https://nationaldppcsc.cdc.gov/s/">
                                        National DPP Customer Service Center
                                    </a>{" "}
                                    detailing your requested change. Please include your
                                    organization’s name and application submission ID (ASID) in this
                                    request.
                                </p>
                            </div>
                            <div className="col-3 text-right">
                                <a href="https://nationaldppcsc.cdc.gov/s/" role="link" title="Link to the National Diabetes Prevention Program Customer Service Center">
                                    <img alt="Link to the National Diabetes Prevention Program Customer Service Center" src={LandingImageSrc} />
                                    <span className="sr-only">customer service center</span>
                                </a>
                            </div>
                        </div>
                    )}
                </Layout>
            )}
        </>
    );
};

export default IntroBanner;
