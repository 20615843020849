import React from "react";
import Constant from "../../common/Constant";
import ContactInfo from "../../common/ContactInfo";
import RadioInput from "../../common/RadioInput";

const DataPreparer = ({
    onChange,
    onKeyDown,
    onValidateBlur,
    onIncludeContactChange,
    onBlurPhoneNumber,
    onSalutationChange,
    isOtherSalutationDataPreparer = false,
    applicationObj,
    errors
}) => {
    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary">Data Preparer (optional)</h2>
            <p className="ml-3 mr-3 mt-3 mb-0">
                <small>{Constant.DATA_PREPARER_INTRO_TEXT}</small>
            </p>
            <div className="card-body">
                <RadioInput
                    onChange={onIncludeContactChange}
                    name={Constant.INCLUDE_DATA_PREPARER}
                    questionText={Constant.DATA_PREPARER_QUESTION_TEXT}
                    noteText={Constant.DATA_PREPARER_NOTE_TEXT}
                    valueOne={true}
                    valueTwo={false}
                    defaultValueOne={true}
                    ariaSelected={applicationObj[`${Constant.INCLUDE_DATA_PREPARER}`] ? true : "false"}
                />
                {applicationObj[`${Constant.INCLUDE_DATA_PREPARER}`] && (
                    <div className="form-group" role="group" aria-label="Data Preparer">
                        <ContactInfo
                            controlTitle="Data Preparer Name"
                            controlSecondaryTitle="Data Preparer Contact Information"
                            controlSecondaryText="Provide the phone number and email address of the organization’s Data Preparer. DPRP staff will use this information to communicate with the organization about data submission issues, if required. The Data Preparer’s contact information will not be included in the DPRP registry or the CDC Find a Program locator."
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onValidateBlur={onValidateBlur}
                            onBlurPhoneNumber={onBlurPhoneNumber}
                            isDataPreparer={true}
                            onSalutationChange={onSalutationChange}
                            isOtherSalutation={isOtherSalutationDataPreparer}
                            salutationDdl={Constant.DATA_PREPARER_SALUTATION}
                            salutation={applicationObj[Constant.DATA_PREPARER_SALUTATION]}
                            firstNameControl={Constant.DATA_PREPARER_FIRST_NAME}
                            firstName={applicationObj[Constant.DATA_PREPARER_FIRST_NAME]}
                            midNameControl={Constant.DATA_PREPARER_MID_NAME}
                            midName={applicationObj[Constant.DATA_PREPARER_MID_NAME]}
                            lastNameControl={Constant.DATA_PREPARER_LAST_NAME}
                            lastName={applicationObj[Constant.DATA_PREPARER_LAST_NAME]}
                            applicantTitleControl={Constant.DATA_PREPARER_AFFLICATION}
                            applicantTitle={
                                applicationObj[Constant.DATA_PREPARER_AFFLICATION]
                            }
                            phoneNumberControl={Constant.DATA_PREPARER_PHONE}
                            phoneNumber={applicationObj[Constant.DATA_PREPARER_PHONE]}
                            phoneNumberAreaCode={Constant.DATA_PREPARER_PHONE_AREA_CODE}
                            phoneNumber3={Constant.DATA_PREPARER_PHONE3}
                            phoneNumber4={Constant.DATA_PREPARER_PHONE4}
                            PhoneExtControl={Constant.DATA_PREPARER_PHONE_EXT}
                            PhoneExt={applicationObj[Constant.DATA_PREPARER_PHONE_EXT]}
                            emailAddressControl={Constant.DATA_PREPARER_EMAIL}
                            emailAddress={applicationObj[Constant.DATA_PREPARER_EMAIL]}
                            emailAddressVerifyControl={Constant.DATA_PREPARER_EMAIL_VERIFY}
                            emailAddressVerify={
                                applicationObj[Constant.DATA_PREPARER_EMAIL_VERIFY]
                            }
                            applicationObj={applicationObj}
                            errors={errors}
                        />
                    </div>
                )}
                {errors[`${Constant.DATA_PREPARER_EMAIL_UNIQUE}`] && (
                    <div className={`alert alert-danger col-4 ml-3`}>
                        {errors[`${Constant.DATA_PREPARER_EMAIL_UNIQUE}`]}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataPreparer;
