import React from "react";
import ErrorMessage from "./ErrorMessage";
import RequiredSpanText from "./RequiredSpanText";

const RadioInput = ({
    ariaSelected,
    ariaGroupLabel,
    containerClassName = "",
    defaultValueOne = false,
    defaultValueTwo = false,
    extraTextInline = false,
    error,
    introText,
    isRequired = false,
    labelOne = "Yes",
    labelTwo = "No",
    name,
    noteText,
    onChange,
    questionText,
    valueOne = 0,
    valueTwo = 1,
    wrapperClassName = ""
}) => (
    <div className={wrapperClassName}>
        <p className="mb-1">
            {" "}
            {isRequired ? <span>{introText} <RequiredSpanText /></span> : introText}
        </p>
        {questionText && <p className="mb-0 mt-2">{questionText}</p>}

        <div className={`form-check-inline ml-3 my-2 ${containerClassName}`} role="group" aria-label={ariaGroupLabel}>
            <label htmlFor={name + "yes"} className="form-check-label">
                <input
                    type="radio"
                    name={name}
                    id={name + "yes"}
                    onChange={onChange}
                    value={valueOne}
                    className="form-check-input"
                    defaultChecked={ariaSelected === valueOne}
                    aria-checked={ariaSelected === valueOne}
                />
                {labelOne}
            </label>
            <label htmlFor={name + "no"} className="form-check-label">
                <input
                    type="radio"
                    name={name}
                    id={name + "no"}
                    onChange={onChange}
                    value={valueTwo}
                    className="form-check-input ml-5"
                    defaultChecked={(ariaSelected != valueOne && ariaSelected != "") ? true : false}
                    aria-checked={(ariaSelected != valueOne && ariaSelected != "") ? true : false }
                />
                {labelTwo}
            </label>
            {extraTextInline && <div className="ml-5">{extraTextInline}</div>}
        </div>
        {noteText && <p className="ml-3 mb-3"><small>{noteText}</small></p>}
        {error && <ErrorMessage className="col-4" errorMessage={error} />}
    </div>
);

export default RadioInput;
