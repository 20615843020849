import React from "react";
import Constant from "../../common/Constant";

export const FileUpload = ({
    applicationObj,
    onChangeUpload,
    totalFileSize,
    disabled = false
}) => {
    return (
        <>
            <input
                id="fileUpload"
                key={applicationObj[`${Constant.RESET_FILE_UPLOAD_TEXT}`]}
                type="file"
                onChange={onChangeUpload}
                multiple
                disabled={disabled}
            />

            {totalFileSize > 0 && (
                <p>
                    <span className="font-weight-bold">Total File Size:</span>{" "}
                    {totalFileSize}MB
                </p>
            )}
        </>
    );
};
