import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as api from "../../api/api";
import LandingImageSrc from "../../images/LandingPageSite.png";
import IntroBanner from "../ui/IntroBanner";
import BeforeYouBegin from "./BeforeYouBegin";
import GeneralInfo from "./GeneralInfojs";
import TypeOfDelivery from "./TypeOfDelivery";
import SecondaryContact from "./SecondaryContact";
import ProgramCoordinator from "./ProgramCoordinator";
import DataPreparer from "./DataPreparer";
import ProgramDeliveryInfo from "./ProgramDeliveryInfo";
import CertificationOfApplication from "./CertificationOfApplication";
import Constant from "../../common/Constant";
import { toast } from "react-toastify";
import { formIsValid } from "../../FormValidation/formValidation";
import {
    compareValue,
    getTodaysDate,
    openNewWindow,
    validateEmail,
    emailHasDomain,
    validateUrl,
    useSetTitle,
} from "../../common/Util";
import Spinner from "../../common/Spinner";
import VerificationAndSubmit from "./VerificationAndSubmit";
import Overlay from "../../common/Overlay";
import { doAnalyticsWithTimeout } from "../../common/analytics";
import ErrorList from "../../common/ErrorList";
import { appDefaultValues } from "../../api/formDefaultValues";
import { useNavigate } from "react-router-dom";
import { Layout } from "../layout/Layout";

function Application(props) {
    //useSetTitle('Application');
    const [loadingApi, setLoadingApi] = useState(true),
        [isSavingApplication, setIsSavingApplication] = useState(false),
        [errors, setErrors] = useState({}),
        [verifyingAddress, setVerifyingAddress] = useState(false),
        //[applicationObj, setApplicationObj] = useState(api.defaultTestObj),//this is use for debugging, it will pre-populated majority of the fields
        [applicationObj, setApplicationObj] = useState(appDefaultValues),
        [addressObj, setAddressObj] = useState({}),
        [isVerifiedAddress, setIsVerifiedAddress] = useState(null),
        [isValidUrl, setIsValidUrl] = useState(),
        [states, setStates] = useState([]),
        [formApprovalInfo, setFormApprovalInfo] = useState([]),
        [organizationCategories, setorganizationCategories] = useState([]),
        [roleTitles, setRoleTitles] = useState([]),
        [programDeliveryModes, setprogramDeliveryMode] = useState([]),
        [combineDeliveryModes, setCombineDelivyModes] = useState([]),
        [interactionMechanismLku, setInteractionMechanismLku] = useState([]),
        [technologyLookUp, setTechnologyLookUp] = useState([]),
        [isOtherSalutation, setIsOtherSalutation] = useState(false),
        [isOtherSalutationSecondary, setIsOtherSalutationSecondary] = useState(
            false
        ),
        [
            isOtherSalutationDataPreparer,
            setIsOtherSalutationDataPreparer
        ] = useState(false),
        [classTypes, setClassTypes] = useState([]),
        [curriculums, setCurriculums] = useState([]),
        [stateId, setStateId] = useState(0),
        [coachTrainingEntityTypes, setcoachTrainingEntityTypes] = useState([]),
        [trainingProviders, setTrainingProviders] = useState([]),
        [calendarDate, setCalendarDate] = useState(getTodaysDate()),
        [isCheckedDataPreparer, setIsCheckedDataPreparer] = useState(false),
        [isCheckedSecondaryContact, setIsCheckedSecondaryContact] = useState(false),
        [isCheckedOtherCurriculum, setIsCheckedOtherCurriculum] = useState(false),
        [totalFileSize, setTotalFileSize] = useState(0),
        [formData, setFormData] = useState(null),
        [isUploadDisabled, setIsUploadDisabled] = useState(false),
        navigate = useNavigate(),
        recaptchaRef = React.createRef();
    //event after components are mounted
    useEffect(function () {
        let _applicationObject = {};
        //get all lookup data and set react state
        api.getAllLookUpData().then(lookUpData => {
            setStates(lookUpData.stateLku.data); // states
            setRoleTitles(lookUpData.roleTitlesLku.data); // role titles
            setorganizationCategories(lookUpData.organizationLku.data); //organization categories
            setprogramDeliveryMode(lookUpData.programDeliveryLku.data); //program delivery mode
            setCombineDelivyModes(lookUpData.programParticipantLku.data); //combination delivery modes
            setClassTypes(lookUpData.classTypeLku.data); //class type
            setCurriculums(lookUpData.curriculumnLku.data); //curriculum
            setcoachTrainingEntityTypes(lookUpData.coachTrainingLku.data); //coach training entity types
            setTrainingProviders(lookUpData.trainingProviderLku.data);
            setFormApprovalInfo(lookUpData.formApprovalInfo.data);
            setInteractionMechanismLku(lookUpData.InteractionMechanism.data);
            setTechnologyLookUp(lookUpData.TechnologyLookUp.data);

            //default values for application object, this applicationObj is model after the Application.cs
            setApplicationObj({
                ...applicationObj,
                [Constant.DELIVERY_MODE]: lookUpData.programDeliveryLku.data[0].id,
                [Constant.SUBMITTED_CURRICULUM_ID]: lookUpData.curriculumnLku.data[0].id,
                [Constant.CURRICULUM]: lookUpData.curriculumnLku.data[0].name,
                [Constant.COACH_TRAINING_ENTITY_ID]: lookUpData.coachTrainingLku.data[0].id,
                [Constant.TRAINING_PROVIDER_ID]: lookUpData.trainingProviderLku.data[0].id
            });
            setLoadingApi(false);
        })

        //analytics
        doAnalyticsWithTimeout(Constant.ANALYTICS_PARMS);
    }, []); // eslint-disable-line 
    //validate form
    function validateForm() {
        let errors = {},
            isValid = false;

        //method to return list of errors to set to react state
        errors = formIsValid(applicationObj);
        isValid = Object.keys(errors).length === 0;

        setErrors(errors);

        //show error list
        if (!isValid) {
            toast.error(<ErrorList errors={errors} />);
        }

        // Form is valid if the errors object has no properties
        return isValid;
    }

    //clear validation text
    function clearValidationText(name) {
        setErrors({
            ...errors,
            [name]: ""
        });
    }

    //return applicationObj value for null or boolean
    function setBoolean(prop) {
        return applicationObj[`${prop}`] != null
            ? applicationObj[`${prop}`] === true
            : false;
    }

    //save application method
    function saveApplication(curriculumId = null, suppFileIDs = null) {
        //parse parameters to match Application.cs object Type, console.log the applicationObj to see the before and after, we can move some of these entries over to the formDefaultValues.js

        const saveApplicaitonObj = {
            ...applicationObj,
            [Constant.SUBMITTED_CURRICULUM_ID]:
                curriculumId === null //curriculumId will be available and updated when user upload/submit a file, if the user is not uploading any files, the curriculumId will be from the radio button list
                    ? applicationObj[Constant.SUBMITTED_CURRICULUM_ID]
                    : curriculumId,
            [Constant.CURRICULUM]:
                applicationObj[Constant.CURRICULUM] === "9999" //if curriculum is other, then grab the curiculum name from the upload description/name input fields
                    ? applicationObj[Constant.CURRICULUM_NAME]
                    : applicationObj[Constant.CURRICULUM],
            [Constant.IS_ADDITIONAL_CONTENT_FILE]:
                applicationObj[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`] === true,
            [Constant.SUPLLEMENTAL_FILES_IND]:
                applicationObj[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`] === true,
            [Constant.SUPPLEMENT_FILE_ID]: suppFileIDs,
            [Constant.IS_CURRICULUM]:
                applicationObj[`${Constant.IS_CURRICULUM}`] === true,
            [Constant.OTHER_CURRICULUM_IND]:
                applicationObj[`${Constant.OTHER_CURRICULUM_IND}`] === true,
            [Constant.ELECTRONIC_SIG_DATE]: new Date(),
            [Constant.MULTI_STATES_CLASS_PROVD]: setBoolean(
                Constant.MULTI_STATES_CLASS_PROVD
            ),
            [Constant.DELIVERY_MODE_OFFER_APP_ACCESS]: setBoolean(
                Constant.DELIVERY_MODE_OFFER_APP_ACCESS
            ),
            [Constant.DELIVERY_MODE_ACCESS_PASSED]: setBoolean(
                Constant.DELIVERY_MODE_ACCESS_PASSED
            ),
            [Constant.DELIVERY_MODE_LOCKED_MODULE]: setBoolean(
                Constant.DELIVERY_MODE_LOCKED_MODULE
            ),
            [Constant.DELIVERY_MODE_SKIP_MODULES]: setBoolean(
                Constant.DELIVERY_MODE_SKIP_MODULES
            ),
            [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL]: setBoolean(
                Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL
            ),
            [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL]: setBoolean(
                Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL
            ),
            [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL]: setBoolean(
                Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL
            ),
            [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL]: setBoolean(
                Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL
            ),
            [Constant.DELIVERY_MODE_MULTIPLE_SESSION]: setBoolean(
                Constant.DELIVERY_MODE_MULTIPLE_SESSION
            ),
            [Constant.PARTICIPANT_DELIVERY_MODE_IDS]:
                applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].length > 0
                    ? applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`]
                    : [],
            [Constant.MULTI_STATE_TERRITORY_LIST]:
                applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`].length > 0
                    ? applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`].map(
                        ({ id }) => id
                    )
                    : [], //mapping to get list of id's only otherwise return null
            [Constant.CLASS_TYPE_EMPLOYEES]: setBoolean(
                Constant.CLASS_TYPE_EMPLOYEES
            ),
            [Constant.CLASS_TYPE_MEMBERS]: setBoolean(Constant.CLASS_TYPE_MEMBERS),
            [Constant.CLASS_TYPE_PUBLIC]: setBoolean(Constant.CLASS_TYPE_PUBLIC),
            [Constant.CLASS_TYPE_OTHERS]: setBoolean(Constant.CLASS_TYPE_OTHERS),
            [Constant.ENROLLMENT_LOCATION]: applicationObj[`${Constant.ENROLLMENT_LOCATION}`].length === 0
                ? null
                : applicationObj[`${Constant.ENROLLMENT_LOCATION}`],
            [Constant.NATIONAL_ONLINE_PROVIDER]: 
                applicationObj[`${Constant.ENROLLMENT_LOCATION}`] === ""
                    ? null
                    : applicationObj[`${Constant.ENROLLMENT_LOCATION}`]
            ,                
        };

        //console.log("Object sending to save: ", saveApplicaitonObj);

        //POST: save application form
        api
            .saveApplicationOjb(saveApplicaitonObj)
            .then(response => {
                if (!response.error) {
                    if (!response.data.errors && !response.data.exceptionMesssage) {
                        toast.success("Application Form Saved Successfully!");
                        setIsSavingApplication(false);
                        navigate("/confirmationpage", { replace: true });;
                    } else {
                        //console.log(response);

                        if (response.data.exceptionMesssage) {
                            toast.error(
                                `An application occurred, (Save Failed) please contact support`
                            );
                            console.error(response.data.exceptionMesssage);
                        } else {
                            //printing the backend validaiton error into the console
                            let errorStr = "";
                            response.data.errors.forEach(error => {
                                errorStr += error + "\n";
                            });
                            console.error(errorStr);

                            errorStr
                                .toLowerCase()
                                .indexOf("previous assinged organization code is not found")
                                ? toast.error(errorStr) &&
                                setErrors({
                                    ...errors,
                                    [Constant.PREV_ASSIGNED_ORG_CODE]: errorStr
                                })
                                : toast.error(
                                    `There are ${response.data.errors.length
                                    } missing required fields`
                                );
                        }
                        setIsSavingApplication(false);
                    }
                } else {
                    toast.error(`Save failed: ${response.error}`);
                    setIsSavingApplication(false);
                }
            })
            .catch(err => {
                toast.error(
                    `An application occurred, (Application Submit) please contact support`
                );
                setIsSavingApplication(false);
                console.error(err);
            });
    }

    //handle file upload and then submit to save application
    function handleSubmit(e) {
        e.preventDefault();
        if (!validateForm(applicationObj)) {
            return;
        }
        setIsSavingApplication(true);
        //validated invisible captcha on form submit
        recaptchaRef.current.execute();
        //if other curriculums, validate file before saving applicaiton

        if (formData) {
            api
                .uploadFileToServer(formData)
                .then(response => {
                    if (!response.data.exceptionMesssage) {
                        toast.success("File Uploaded Successfully!");
                        //save application with new return curriculum id or collection of SuppFileIDs
                        saveApplication(
                            response.data.curriculumID,
                            response.data.suppFileIDs
                        );
                    } else {
                        toast.error(
                            `File Upload Failed: ${response.data.exceptionMesssage}`
                        );
                        setIsSavingApplication(false);
                        console.error(response);
                    }
                })
                .catch(err => {
                    //handle error
                    toast.error(
                        `An error occurred, (File Upload) please contact support`
                    );
                    setIsSavingApplication(false);
                    console.error(err);
                });
        } else {
            //save application with new return curriculum id
            saveApplication();
        }
    }

    //handle on keydown for phone number
    function handleOnKeyDown(event) {
        const { name, value } = event.target;

        //disable non-numeric inputs for phone number
        if (
            event.which !== 37 && //arrow keys
            event.which !== 38 &&
            event.which !== 39 &&
            event.which !== 40 &&
            event.which !== 46 && //delete
            (event.which < 48 || event.which > 57) &&
            event.which !== 8 &&
            !event.shiftKey &&
            event.which !== 9
        ) {
            event.preventDefault();
        } else {
            if (
                (compareValue(name, Constant.ORG_PHONENUMBER) ||
                    compareValue(name, Constant.CONTACT_PHONE) ||
                    compareValue(name, Constant.SECONDARY_CONTACT_PHONE) ||
                    compareValue(name, Constant.DATA_PREPARER_PHONE)) &&
                event.keyCode !== 8 &&
                event.which !== 46
            ) {
                let fullPhoneNumber = value;
                //console.log(value);
                let phoneNumber = fullPhoneNumber.replace(/\D+/g, ""),
                    phoneNumberArea = phoneNumber.substr(0, 3),
                    phoneNumber3 = phoneNumber.substr(3, 3),
                    phoneNumber4 = phoneNumber.substr(6, 4);

                let formatPhoneNumber = "";

                if (phoneNumber.length === 3) {
                    formatPhoneNumber = `(${phoneNumberArea}) `;
                } else if (phoneNumber.length > 3 && phoneNumber.length < 7) {
                    formatPhoneNumber = `(${phoneNumberArea}) ${phoneNumber3}`;
                } else if (phoneNumber.length > 7) {
                    formatPhoneNumber = `(${phoneNumberArea}) ${phoneNumber3} - ${phoneNumber4}`;
                } else {
                    formatPhoneNumber = value;
                }

                setApplicationObj({
                    ...applicationObj,
                    [name]: formatPhoneNumber
                });

                return;
            }
        }
    }

    function handleChangeEnrollmentLocationRadio(event) {
        const { name, value } = event.target;
        let _value = value === "true" ? true : false;
        setErrors({
            ...errors,
            [Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE]: "",
            [Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE]: "",
            [Constant.ENROLLMENT_LOCATION]: "",
        });
        setApplicationObj({
            ...applicationObj,
            [name]: _value,
            [Constant.NATIONAL_ONLINE_PROVIDER]: _value,
        });
    }

    //handle changes to binded fields for the applicationObj
    function handleChange(event) {
        const { name, value, selectedIndex, checked } = event.target;
        const todaysDate = new Date().toJSON().slice(0, 10);
        //console.log("handle change: ", name, value);

        //parse bool to true if bool is a string
        let _value = value == "true" ? true : value; // eslint-disable-line

        //reset validation errors message
        clearValidationText(name);

        if(name === Constant.PROJECTED_START_DATE && value < todaysDate) {
            setErrors({
                ...errors,
                [Constant.PROJECTED_START_DATE]: Constant.PROJECTED_START_DATE_VALIDATE_MSG,
            })
        }

        if(name === Constant.SECONDARY_CONTACT_TITLE)

        //parse as int
        if (
            compareValue(name, Constant.TECHNOLOGY_ID) ||
            compareValue(name, Constant.INTERACTION_ID)
        ) {
            let _applicationObj = {};
            _value = Number(value);

            //eslint-disable-next-line
            if (value != "4" && compareValue(name, Constant.INTERACTION_ID)) {
                //if interaction is not "other"
                _applicationObj = {
                    ...applicationObj,
                    [name]: _value,
                    [Constant.INTERACTION_OTHER]: ""
                };

                //reset error for "other" interaction
                setErrors({
                    ...errors,
                    [Constant.INTERACTION_OTHER]: ""
                });
                //eslint-disable-next-line
            } else if (value != "6" && compareValue(name, Constant.TECHNOLOGY_ID)) {
                // if technology is not "other"
                _applicationObj = {
                    ...applicationObj,
                    [name]: _value,
                    [Constant.TECHNOLOGY_OTHER]: ""
                };

                //reset error message for "other" technology
                setErrors({
                    ...errors,
                    [Constant.TECHNOLOGY_OTHER]: ""
                });
            } else {
                _applicationObj = {
                    ...applicationObj,
                    [name]: _value
                };
            }

            //set applicaiton object
            setApplicationObj(_applicationObj);
            return;
        }

        //reset other title for secondary contact when title is not 'other'
        if (name === Constant.SECONDARY_CONTACT_TITLE && selectedIndex != 14) {
            setApplicationObj({
                ...applicationObj,
                [name]: selectedIndex === 0 ? null : selectedIndex,
                [Constant.SECONDARY_CONTACT_TITLE_OTHER]: ""
            });
            return;
        }

        if (name === Constant.SECONDARY_CONTACT_TITLE) {
            setApplicationObj({
                ...applicationObj,
                [name]: selectedIndex,
            });
            return;
        }

        //reset other title for program coordinator contact when title is not 'other'
        if (name === Constant.CONTACT_TITLE && selectedIndex != 14) {
            setApplicationObj({
                ...applicationObj,
                [name]: selectedIndex === 0 ? null : selectedIndex,
                [Constant.CONTACT_TITLE_OTHER]: ""
            });
            return;
        }

        if (name === Constant.CONTACT_TITLE) {
            setApplicationObj({
                ...applicationObj,
                [name]: selectedIndex,
            });
            return;
        }

        //if program coordinator select "other" for salutation
        if (name === Constant.CONTACT_SALUTATION && selectedIndex === 6) {
            setIsOtherSalutation(true);

            setApplicationObj({
                ...applicationObj,
                [name]: isOtherSalutation === true && _value
            });
            return;
        } else if (name === Constant.CONTACT_SALUTATION && selectedIndex < 6) {
            setIsOtherSalutation(false);
        }

        //if secondary contact select other "option" for salutation
        if (name === Constant.SECONDARY_CONTACT_SALUTATION && selectedIndex === 6) {
            setIsOtherSalutationSecondary(true);
            setApplicationObj({
                ...applicationObj,
                [name]: isOtherSalutationSecondary === true && _value
            });
            return;
        } else if (
            name === Constant.SECONDARY_CONTACT_SALUTATION &&
            selectedIndex < 6
        ) {
            setIsOtherSalutationSecondary(false);
        }

        //setOtherSalutation for  data preprarer,selectedIndex === 6 is for other salutation
        if (name === Constant.DATA_PREPARER_SALUTATION && selectedIndex === 6) {
            setIsOtherSalutationDataPreparer(true);
            setApplicationObj({
                ...applicationObj,
                [name]: isOtherSalutationDataPreparer === true && _value
            });
            return;
        } else if (
            name === Constant.DATA_PREPARER_SALUTATION &&
            selectedIndex < 6
        ) {
            setIsOtherSalutationDataPreparer(false);
        }

        const emailFields = [
            Constant.CONTACT_EMAIL,
            Constant.SECONDARY_CONTACT_CONTACT_EMAIL,
            Constant.DATA_PREPARER_EMAIL,
            Constant.CONTACT_EMAIL_VERIFY,
            Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY,
            Constant.DATA_PREPARER_EMAIL_VERIFY
        ];

        if (emailFields.includes(name)) {
            setApplicationObj({
                ...applicationObj,
                [name]: value.toLowerCase()
            });
            return;
        }

        //parse application status id
        if (name === Constant.APPLICATION_STATUS_ID) _value = Number(value);

        //parse previous org name to type number
        if (compareValue(name, Constant.PREV_ASSIGNED_ORG_CODE))
            _value = Number(value);

        //parse has secondary contact and data preparrer to bool
        if (
            compareValue(name, Constant.INCLUDE_SECONDARY_CONTACT) ||
            compareValue(name, Constant.INCLUDE_DATA_PREPARER)
        ) {
            _value = value === "true";

            //if false, clear all secondary contact fields
            if (compareValue(name, Constant.INCLUDE_SECONDARY_CONTACT) && !_value)
                clearContactFields.secondaryContact();

            //if false, clear all data preparer contact fields
            if (compareValue(name, Constant.INCLUDE_DATA_PREPARER) && !_value) {
                clearContactFields.dataPreparer();
            }
        }

        //parse has enrollment location

        //show hide data preparer afflication contact info
        if (
            compareValue(name, Constant.DATA_PREPARER_AFFILIATION) &&
            value !== Constant.DATA_PREPARER_NEW_CONTACT_TXT
        ) {
            clearContactFields.dataPreparer();
        }

        //if nationalprovider, clears out  states and territory array
        if (compareValue(name, Constant.NATIONAL_ONLINE_PROVIDER) && !checked) {
            _value = checked;
        } else if (compareValue(name, Constant.NATIONAL_ONLINE_PROVIDER)) {
            setApplicationObj({
                ...applicationObj,
                [name]: checked,
                [Constant.MULTI_STATE_TERRITORY_LIST]: []
            });

            return;
        }

        //is new applicaiton or re-apply
        if (compareValue(name, Constant.REAPPLY)) _value = value === "true";

        //is file upload additional content or curriculum
        if (compareValue(name, Constant.IS_ADDITIONAL_CONTENT_FILE))
            _value = value == "true"; // eslint-disable-line

        //if curriculum file size is over 20mb
        if (compareValue(name, Constant.FILE_OVER_SIZE)) {
            let _appplicationObj = {};

            //if over 20mb is checked, allow form to submit and reset upload info
            if (checked) {
                setIsUploadDisabled(true);
                setFormData(null);
                document.getElementById('fileUpload').value = "";
                setErrors({
                    ...errors,
                    [Constant.FILE_UPLOAD]: ""
                });
                setTotalFileSize(0);
            } else {
                setIsUploadDisabled(false);
            }

            if (isCheckedOtherCurriculum) {
                _appplicationObj = {
                    ...applicationObj,
                    [Constant.CURRICULUM_FILE_OVER_SIZE]: checked
                };
            } else {
                _appplicationObj = {
                    ...applicationObj,
                    [Constant.SUPPLEMENTAL_FILE_OVERSIZE]: checked
                };
            }
            setApplicationObj(_appplicationObj);
            return;
        }

        //set application object, the conditional below clears out the default text for "other salutation" on load
        setApplicationObj({
            ...applicationObj,
            [name]: _value
        });
    }

    //validate unique email between primary contact, secondary and data preprarer
    function validateUnqiueEmail(name, value) {
        let _value = value.toLowerCase(),
            isValided = true;

        //test for unqiue secondary contact email
        if (
            compareValue(name, Constant.SECONDARY_CONTACT_CONTACT_EMAIL) &&
            _value !== ""
        ) {
            if (
                (_value === applicationObj[`${Constant.CONTACT_EMAIL}`] &&
                    applicationObj[`${Constant.CONTACT_EMAIL}`] !== "") ||
                (_value === applicationObj[`${Constant.DATA_PREPARER_EMAIL}`] &&
                    applicationObj[`${Constant.DATA_PREPARER_EMAIL}`] !== "")
            ) {
                setErrors({
                    ...errors,
                    [Constant.SECONDARY_CONTACT_EMAIL_UNIQUE]:
                        Constant.EMAIL_UNIQUE_FAIL_TXT
                });
                isValided = false;
            } else {
                clearValidationText(Constant.SECONDARY_CONTACT_EMAIL_UNIQUE);
                return;
            }
        }

        //test for unique data preparer email
        if (compareValue(name, Constant.DATA_PREPARER_EMAIL) && _value !== "") {
            if (
                (value === applicationObj[`${Constant.CONTACT_EMAIL}`] &&
                    applicationObj[`${Constant.CONTACT_EMAIL}`] !== "") ||
                (value ===
                    applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] &&
                    applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] !== "")
            ) {
                setErrors({
                    ...errors,
                    [Constant.DATA_PREPARER_EMAIL_UNIQUE]: Constant.EMAIL_UNIQUE_FAIL_TXT
                });
                isValided = false;
            } else {
                clearValidationText(Constant.DATA_PREPARER_EMAIL_UNIQUE);
                return;
            }
        }

        //secondary contact verify email, test to ensure unique email
        if (
            compareValue(name, Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY) &&
            _value !== ""
        ) {
            if (
                applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] ===
                applicationObj[`${Constant.CONTACT_EMAIL}`] ||
                applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] ===
                applicationObj[`${Constant.DATA_PREPARER_EMAIL}`]
            ) {
                setErrors({
                    ...errors,
                    [Constant.SECONDARY_CONTACT_EMAIL_UNIQUE]:
                        Constant.EMAIL_UNIQUE_FAIL_TXT
                });
                isValided = false;
            } else {
                clearValidationText(Constant.SECONDARY_CONTACT_EMAIL_UNIQUE);
            }
        }

        //data preprare verify email, test to ensure unique email
        if (
            compareValue(name, Constant.DATA_PREPARER_EMAIL_VERIFY) &&
            _value !== ""
        ) {
            if (
                applicationObj[`${Constant.DATA_PREPARER_EMAIL}`] ===
                applicationObj[`${Constant.CONTACT_EMAIL}`] ||
                applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] ===
                applicationObj[`${Constant.DATA_PREPARER_EMAIL}`]
            ) {
                setErrors({
                    ...errors,
                    [Constant.DATA_PREPARER_EMAIL_UNIQUE]: Constant.EMAIL_UNIQUE_FAIL_TXT
                });
                isValided = false;
            } else {
                clearValidationText(Constant.DATA_PREPARER_EMAIL_UNIQUE);
            }
        }

        return isValided;
    }

    //hanlde validation when losing focus
    //NOTE: "dataset" here is a native custom declared dom attribute. It's being used to get a custom value because react can only bind to one "value" property. It's not an actual "dataset" from the db
    function handleValidateOnBlur(event) {
        const { name, value, dataset } = event.target;
        let _errorsObj = {};

        if (!validateUnqiueEmail(name, value, dataset.validatemsg)) return;

        if (!applicationObj[`${name}`]) {
            _errorsObj = {
                ...errors,
                [name]: dataset.validatemsg
            };
        } else {
            //validate if email matches when leaving the verify email input
            if (name === Constant.ORG_EMAIL && !validateEmail(applicationObj[`${name}`])) {
                _errorsObj = {
                    ...errors,
                    [name]: Constant.EMAIL_NOT_VALID_MSG
                };
            } else if (name === Constant.ORG_EMAIL && emailHasDomain(applicationObj[`${name}`], Constant.ORG_EMAIL_INVALID_DOMAIN)) {
                _errorsObj = {
                    ...errors,
                    [name]: Constant.ORG_EMAIL_DOMAIN_VALIDATION_MSG
                };
            } else if (name.indexOf("EmailVerify") > -1) {
                if (
                    applicationObj[`${name.substring(0, name.indexOf("Verify"))}`] &&
                    applicationObj[`${name}`] &&
                    applicationObj[`${name.substring(0, name.indexOf("Verify"))}`] !==
                    applicationObj[`${name}`]
                ) {
                    _errorsObj = {
                        ...errors,
                        [`${name}`]: "Email address does not match"
                    };
                }
            } else if (
                name.indexOf("Email") > -1 &&
                !validateEmail(applicationObj[`${name}`])
            ) {
                _errorsObj = {
                    ...errors,
                    [name]: "Incorrect Email Format"
                };
            } else {
                _errorsObj = {
                    ...errors,
                    [name]: ""
                };
            }
        }

        setErrors(_errorsObj);
    }

    function handleChangeTypeOfDelivery(event) {
        const { name, value } = event.target;

        //setting string as false because it breaks some of the controls when setting it to a boolean
        let _delivery_mode_distance = "false",
            _delivery_mode_in_person = "false",
            _delivery_mode_online = "false";

        clearErrorsMsgDeliveryMode.clearAllErrorsMsgDeliveryMode();

        //Delivery mode: in person
        if (value === "1") {
            _delivery_mode_in_person = true;
        }

        //Delivery mode: online
        if (value === "2") {
            _delivery_mode_online = true;
        }

        //Delivery mode: distance learning
        if (value === "3") {
            _delivery_mode_distance = true;
        }

        //Delivery mode: In-person with a distance learning component
        if (value === "5") {
            _delivery_mode_in_person = true;
            _delivery_mode_distance = true;
        }

        setApplicationObj({
            ...applicationObj,
            [name]: value,
            [`${Constant.DELIVERY_MODE_DISATANCE}`]: _delivery_mode_distance,
            [Constant.DELIVERY_MODE_IN_PERSON]: _delivery_mode_in_person,
            [Constant.DELIVERY_MODE_ONLINE]: _delivery_mode_online,
            [Constant.PARTICIPANT_DELIVERY_MODE_IDS]: 
                value === "4"
                    ? [2]
                    : value === "5"
                        ? [1,3]
                        : [],
            [Constant.MULTI_STATES_CLASS_PROVD]: "",
            [Constant.DELIVERY_MODE_OFFER_APP_ACCESS]: "",
            [Constant.DELIVERY_MODE_LOCKED_MODULE]: "",
            [Constant.DELIVERY_MODE_ACCESS_PASSED]: "",
            [Constant.DELIVERY_MODE_SKIP_MODULES]: "",
            [Constant.DELIVERY_MODE_MULTIPLE_SESSION]: "",
            [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL]: "",
            [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL]: "",
            [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL]: "",
            [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL]: "",
            [Constant.INTERACTION_ID]: null,
            [Constant.TECHNOLOGY_ID]: null,
            [Constant.ENROLLMENT_LOCATION]: "",
            [Constant.MULTI_STATE_TERRITORY_LIST]: [],
        });
    }

    function handleChangeDeliveryAttestationCheckbox(event) {
        const { name, checked } = event.target;
        if (checked === false)
            setErrors({
                ...errors,
                [Constant.DELIVERY_ATTESTATION]: Constant.DELIVERY_ATTESTATION_VALIDATE_MSG
            });
        else
            setErrors({
                ...errors,
                [Constant.DELIVERY_ATTESTATION]: ""
            })
    }

    function handleChangeSocialVulnerabilityIndexCheckbox(event) {

    }

    function handleChangeDeliveryModecheckBox(event) {
        const { name, dataset, checked } = event.target;
        //store combination delivery mode in array
        let deliveryModeList =
            applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`];
        deliveryModeList.push(Number(dataset.deliverymodevalue));

        let uniqueArr = [...new Set(deliveryModeList)];

        //remove delivery mode id, if checkbox is unchecked
        if (checked === false) {
            uniqueArr = uniqueArr.filter(
                item => item !== Number(dataset.deliverymodevalue)
            );
        }

        setApplicationObj({
            ...applicationObj,
            [name]: uniqueArr, //make the array push unique values
            [dataset.deliverymodename]: checked
        });

        //eslint-disable-next-line
        if (dataset.deliverymodevalue == "1" && !checked) {
            clearErrorsMsgDeliveryMode.inPerson();
        }
        //eslint-disable-next-line
        if (dataset.deliverymodevalue == "2" && !checked) {
            clearErrorsMsgDeliveryMode.online();
        }
        //eslint-disable-next-line
        if (dataset.deliverymodevalue == "3" && !checked) {
            clearErrorsMsgDeliveryMode.distanceLearning();
        }
    }

    function handleStateChange(target) {
        const { name, value } = target;
        clearValidationText(name);
        setStateId(value);
        setApplicationObj({
            ...applicationObj,
            [name]: value,
            [Constant.PHYSICAL_STATE_ABBR]: states.find(state => state.id === value).stateAbbr
        });
    }

    function handleSalutationChange(event) {
        const { name, value } = event.target;
        setApplicationObj({ ...applicationObj, [name]: value });
    }

    function handleDateChange(value) {
        setCalendarDate(value);
        setApplicationObj({
            ...applicationObj,
            [Constant.ELECTRONIC_SIG_DATE]: value
        });
    }

    //event to add multi state/territory selections to list
    function handleStateSelectChange(newList) {
        setApplicationObj({
            ...applicationObj,
            [Constant.MULTI_STATE_TERRITORY_LIST]: newList
        });
        if (newList.length >= 1) {
            setErrors({
                ...errors,
                [Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE]: "",
                [Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE]: ""
            });
        }
    }

    //clear secondary dary and data preparer fields, both modules are optional entries
    const clearContactFields = {
        secondaryContact: (name, value) => {
            setApplicationObj({
                ...applicationObj,
                [name]: value === "true",
                [Constant.SECONDARY_CONTACT_SALUTATION]: "",
                [Constant.SECONDARY_CONTACT_FIRST_NAME]: "",
                [Constant.SECONDARY_CONTACT_MID_NAME]: "",
                [Constant.SECONDARY_CONTACT_LAST_NAME]: "",
                [Constant.SECONDARY_CONTACT_TITLE]: null,
                [Constant.SECONDARY_CONTACT_TITLE_OTHER]: "",
                [Constant.SECONDARY_CONTACT_PHONE_AREA_CODE]: "",
                [Constant.SECONDARY_CONTACT_PHONE]: "",
                [Constant.SECONDARY_CONTACT_PHONE3]: "",
                [Constant.SECONDARY_CONTACT_PHONE4]: "",
                [Constant.SECONDARY_CONTACT_PHONE_EXT]: "",
                [Constant.SECONDARY_CONTACT_CONTACT_EMAIL]: "",
                [Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY]: ""
            });
            clearValidationText(Constant.SECONDARY_CONTACT_PHONE);
        },
        dataPreparer: (name, value) => {
            setApplicationObj({
                ...applicationObj,
                [name]: value === "true",
                [Constant.DATA_PREPARER_SALUTATION]: "",
                [Constant.DATA_PREPARER_FIRST_NAME]: "",
                [Constant.DATA_PREPARER_MID_NAME]: "",
                [Constant.DATA_PREPARER_LAST_NAME]: "",
                [Constant.DATA_PREPARER_AFFLICATION]: "",
                [Constant.DATA_PREPARER_TITLE]: null,
                [Constant.DATA_PREPARER_PHONE_AREA_CODE]: "",
                [Constant.DATA_PREPARER_PHONE]: "",
                [Constant.DATA_PREPARER_PHONE3]: "",
                [Constant.DATA_PREPARER_PHONE4]: "",
                [Constant.DATA_PREPARER_PHONE_EXT]: "",
                [Constant.DATA_PREPARER_EMAIL]: "",
                [Constant.DATA_PREPARER_EMAIL_VERIFY]: "",
                [Constant.DATA_PREPARER_AFFILIATION]: "",
                [Constant.DATA_PREPARER_TYPE]: Constant.DATA_PREPARER_NEW_CONTACT_TXT
            });
            clearValidationText(Constant.DATA_PREPARER_PHONE);
        }
    };

    //clear UI errors messages for deliverymode combination checkboxes
    const clearErrorsMsgDeliveryMode = {
        inPerson: () => {
            setErrors({
                ...errors,
                [Constant.MULTI_STATES_CLASS_PROVD]: "",
                [Constant.DELIVERY_ATTESTATION]: "",
                [Constant.ENROLLMENT_LOCATION]: "",
                [Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE]: "",
            });
        },
        online: () => {
            setErrors({
                ...errors,
                [Constant.NATIONAL_ONLINE_PROVIDER]: "",
                [Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE]: "",
                [Constant.INTERACTION_OTHER]: "",
                [Constant.DELIVERY_MODE_OFFER_APP_ACCESS]: "",
                [Constant.INTERACTION_ID]: "",
                [Constant.DELIVERY_MODE_ACCESS_PASSED]: "",
                [Constant.DELIVERY_MODE_LOCKED_MODULE]: "",
                [Constant.DELIVERY_MODE_SKIP_MODULES]: "",
                [Constant.DELIVERY_MODE_MULTIPLE_SESSION]: "",
                [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL]: "",
                [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL]: "",
                [Constant.DELIVERY_ATTESTATION]: "",
                [Constant.ENROLLMENT_LOCATION]: ""
            });
        },
        distanceLearning: () => {
            setErrors({
                ...errors,
                [Constant.TECHNOLOGY_ID]: "",
                [Constant.TECHNOLOGY_OTHER]: "",
                [Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE]: "",
                [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL]: "",
                [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL]: "",
                [Constant.DELIVERY_ATTESTATION]: "",
                [Constant.ENROLLMENT_LOCATION]: "",
            });
        },
        clearAllErrorsMsgDeliveryMode: () => {
            setErrors({
                ...errors,
                [Constant.MULTI_STATES_CLASS_PROVD]: "",
                [Constant.NATIONAL_ONLINE_PROVIDER]: "",
                [Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE]: "",
                [Constant.INTERACTION_OTHER]: "",
                [Constant.DELIVERY_MODE_OFFER_APP_ACCESS]: "",
                [Constant.INTERACTION_ID]: "",
                [Constant.DELIVERY_MODE_ACCESS_PASSED]: "",
                [Constant.DELIVERY_MODE_LOCKED_MODULE]: "",
                [Constant.DELIVERY_MODE_SKIP_MODULES]: "",
                [Constant.DELIVERY_MODE_MULTIPLE_SESSION]: "",
                [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_OL]: "",
                [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_OL]: "",
                [Constant.TECHNOLOGY_ID]: "",
                [Constant.TECHNOLOGY_OTHER]: "",
                [Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE]: "",
                [Constant.DELIVERY_MODE_PW_TRANSMITTED_ELECTRONIC_DL]: "",
                [Constant.DELIVERY_MODE_PA_TRANSMITTED_ELETRONIC_DL]: "",
                [Constant.DELIVERY_ATTESTATION]: "",
                [Constant.ENROLLMENT_LOCATION]: ""
            });
        }
    };

    //event for projected start date change
    function handleIncludeProjectedStartDateChange(event) {
        const { name, value } = event.target;
        let _value = value === "true" ? true : false;
        setApplicationObj({
            ...applicationObj,
            [name]: _value
        });
        setErrors({
            ...errors,
            [Constant.INCLUDE_PROJECTED_START_DATE]: ""
        });
    }

    

    //event for secondary conctact and data preparer
    function handleContactChange(event) {
        const { name, value } = event.target;
        //console.log(name, value);
        let _value = value === "true";

        if (compareValue(name, Constant.INCLUDE_SECONDARY_CONTACT) && !_value) {
            clearContactFields.secondaryContact(name, _value);
            clearValidationText(Constant.SECONDARY_CONTACT_EMAIL_UNIQUE);
            return;
        } else if (compareValue(name, Constant.INCLUDE_DATA_PREPARER) && !_value) {
            clearContactFields.dataPreparer(name, _value);
            clearValidationText(Constant.DATA_PREPARER_EMAIL_UNIQUE);
            return;
        } else if (compareValue(name, Constant.INCLUDE_SECONDARY_CONTACT) && _value) {
            setApplicationObj({ 
                ...applicationObj,
                [name]: _value 
            });
        } else if (compareValue(name, Constant.INCLUDE_DATA_PREPARER) && _value) {
            setApplicationObj({ 
                ...applicationObj,
                [name]: _value,
                [Constant.DATA_PREPARER_TITLE]: 14,
            });
        }
    }

    //event to determine if secondary or data preparer module
    function handleOnOptionalChange(event) {
        const { name, checked } = event.target;

        name === "secondary contact" && setIsCheckedSecondaryContact(checked);
        name === "data preparer" && setIsCheckedDataPreparer(checked);

        setApplicationObj({
            ...applicationObj,
            [name]: checked
        });
    }

    //file upload onChange event
    function handleChangeUpload(e) {
        const fileTypeArr = ["doc", "docx", "pdf", "xls", "xlsx"];

        let errorMsg = "";

        //loop through file to test length of file name
        for (let val of e.target.files) {
            if (val.name.length > 200) {
                errorMsg += `<li>File name is too long: <strong>${val.name
                    }</strong></li> `;
            }
            if (!fileTypeArr.includes(val.name.split(".").pop())) {
                errorMsg += `<li>Incorrect file type: <strong>${val.name
                    }</strong></li>`;
            }
        }

        //validate number of files
        if (e.target.files.length > 20) {
            errorMsg += `<li>Too many files uploaded: <strong>${e.target.files.length
                }</strong> </li>`;
        }

        //upload files method
        let _formData = new FormData();

        //curriculum name
        _formData.append(
            Constant.CURRICULUM_NAME,
            applicationObj[Constant.CURRICULUM_NAME] || ""
        );

        //curriculum description
        _formData.append(
            Constant.CURRICULUM_DESCRIPTION,
            applicationObj[Constant.CURRICULUM_DESCRIPTION] || ""
        );

        //flag to determine if uploaded file(s) is curriculum or additional file
        _formData.append(
            Constant.IS_CURRICULUM,
            applicationObj[Constant.IS_CURRICULUM] === true
        );

        //eslint-disable-next-line
        Object.keys(e.target.files).map(function (keyName, keyIndex) {
            _formData.append("formFiles", e.target.files[keyName]);
        });

        setFormData(_formData);

        //create new array to hold and get sum of filze size
        const fileSizeArr = [];

        for (const el of _formData.entries()) {
            fileSizeArr.push(el[1].size);
        }

        //calculate total size of file upload
        let totalFileSize = (
            fileSizeArr.reduce((a, b) => a || 0 + b || 0, 0) /
            (1024 * 1024)
        ).toFixed(2);

        //max total file size is 20mb
        if (totalFileSize > 20) {
            errorMsg += "<li>File size too large </li>";
        }

        //set error message for file upload to display right after upload
        setErrors({
            ...errors,
            [Constant.FILE_UPLOAD]: errorMsg
        });

        //set application object
        setApplicationObj({
            ...applicationObj,
            [Constant.OTHER_CURRICULUM_IND]:
                applicationObj[`${Constant.IS_CURRICULUM}`],
            [Constant.FILE_SIZE_MB]: totalFileSize,
            [Constant.FILE_UPLOAD]: errorMsg
        });

        setTotalFileSize(totalFileSize);
    }

    //verify address method
    function verifyAddress(e) {
        e.preventDefault();
        setVerifyingAddress(true);
        //set address object shape to send to verify, the parameter is from the application object
        const _addressObj = {
            Address1: applicationObj.PhysicalAddress,
            Address2: applicationObj.PhysicalAddress2,
            City: applicationObj.PhysicalCity,
            State: applicationObj[`${Constant.PHYSICAL_STATE_ABBR}`],
            Zip4: applicationObj.PhysicalZip4,
            Zip5: applicationObj.PhysicalZip5
        };

        //send address object to verify
        api.verifyAddress(_addressObj).then(addressData => {
            if (!addressData.error) {
                setAddressObj(addressData);
                setIsVerifiedAddress(true);
                setStateId(states.find(state => state.stateAbbr === addressData.state).id);
                setApplicationObj({
                    ...applicationObj,
                    [Constant.PHYSICAL_STATE_ID]: stateId,
                    [Constant.PHYSICAL_ADDRESS]: addressData.address1,
                    [Constant.PHYSICAL_ADDRESS2]: addressData.address2,
                    [Constant.PHYSICAL_CITY]: addressData.city,
                    [Constant.PHYSICAL_STATE_ABBR]: addressData.state,
                    [Constant.PHYSICAL_ZIP5]: addressData.zip5,
                    [Constant.PHYSICAL_ZIP4]: addressData.zip4,
                    [Constant.ADDRESS_VALIDATED_IND]: true
                });
            } else {
                setIsVerifiedAddress(false);
                setApplicationObj({
                    ...applicationObj,
                    [Constant.ADDRESS_VALIDATED_IND]: false
                });
            }
            setVerifyingAddress(false);
        });
    }

    //event for validating web address
    function handleCheckUrlClick(e) {
        e.preventDefault();
        let url = applicationObj[`${Constant.WEBADDRESS}`] || "",
            _isValidUrl = validateUrl(url);

        setIsValidUrl(_isValidUrl);

        //if user doesn't use http or https, add https as default to the url
        if (_isValidUrl) {
            if (
                url.toLowerCase().indexOf("http://") < 0 &&
                url.toLowerCase().indexOf("https://") < 0
            ) {
                if (url.toLowerCase().indexOf("www")) {
                    url = "https://www." + url;
                } else {
                    url = "https://" + url;
                }

                setApplicationObj({
                    ...applicationObj,
                    [Constant.WEBADDRESS]: url
                });

                //open new window to preview if url is valid web address
                openNewWindow(url, 600, 600);
            } else {
                openNewWindow(url, 600, 600);
            }
        }
    }

    //onblur method contact phone number
    function handleOnBlurPhoneNumber(phoneNumberFull, areaCode, phone3, phone4) {
        //parse out phone number
        let fullPhoneNumber = phoneNumberFull,
            regx = /^(?=.*[0-9])[- ()0-9]+$/,
            isValidPhoneNumber = regx.test(applicationObj[fullPhoneNumber]);

        if (applicationObj[fullPhoneNumber]) {
            let phoneNumber = applicationObj[fullPhoneNumber].replace(/\D/g, ""),
                phoneNumberArea = phoneNumber.substr(0, 3),
                phoneNumber3 = phoneNumber.substr(3, 3),
                phoneNumber4 = phoneNumber.substr(6, 4);

            let formatPhoneNumber = applicationObj[fullPhoneNumber];

            if (isValidPhoneNumber && phoneNumber.length === 10) {
                formatPhoneNumber = `(${phoneNumberArea}) ${phoneNumber3} - ${phoneNumber4}`;
            }

            //set parsed phone number into application object
            setApplicationObj({
                ...applicationObj,
                [fullPhoneNumber]: formatPhoneNumber,
                [areaCode]: phoneNumberArea,
                [phone3]: phoneNumber3,
                [phone4]: phoneNumber4
            });

            //set errors for incorrent phone number format
            setErrors({
                ...errors,
                [fullPhoneNumber]: !isValidPhoneNumber
                    ? "Incorrect format"
                    : phoneNumber.length < 10
                        ? "Phone number is too short"
                        : phoneNumber.length > 10
                            ? "Phone number is too long"
                            : ""
            });
        } else {
            setErrors({
                ...errors,
                [fullPhoneNumber]: Constant.CONTACT_PHONE_VALIDATE_MSG
            });
        }
    }

    //handle event for other mechanism/mode and other technology used
    function hanldeOnOtherInputChange(event) {
        const { name, value } = event.target;

        setApplicationObj({ ...applicationObj, [name]: value });
    }

    //handle event for cirriculum
    function handleOtherCurriculum(event) {
        const { name, value } = event.target;
        let _applicaitonObj = {};

        setIsCheckedOtherCurriculum(value === "9999");
        setIsUploadDisabled(false);

        clearValidationText(Constant.CURRICULUM);
        clearValidationText(Constant.FILE_UPLOAD);
        //if other curriculum is not selected to "other" or 9999, then curriculum name will be from radio buttons
        if (value === "9999") {
            _applicaitonObj = {
                ...applicationObj,
                [Constant.CURRICULUM]: value,
                [Constant.IS_CURRICULUM]: true,
                [Constant.IS_ADDITIONAL_CONTENT_FILE]: false,
                [Constant.RESET_FILE_UPLOAD_TEXT]: "resetOtherCurriculum",
                [Constant.IS_OTHER_CURRICULUM_OPTION_ID]: true,
                [Constant.SUBMITTED_CURRICULUM_ID]: null
            };
        } else { //CURRICULUM_FILE_OVER_SIZE
            _applicaitonObj = {
                ...applicationObj,
                [name]: Number(value),
                [Constant.CURRICULUM]: event.target.getAttribute("data-curriculumname"),
                [Constant.IS_CURRICULUM]: "false",
                [Constant.RESET_FILE_UPLOAD_TEXT]: "resetNotOtherCurriculum",
                [Constant.IS_OTHER_CURRICULUM_OPTION_ID]: false
            };
        }
        //set application object
        setApplicationObj({
            ..._applicaitonObj,
            [Constant.FILE_SIZE_MB]: "0",
            [Constant.CURRICULUM_NAME]: "",
            [Constant.CURRICULUM_DESCRIPTION]: "",
            [Constant.CURRICULUM_FILE_OVER_SIZE]: false,
            [Constant.SUPPLEMENTAL_FILE_OVERSIZE]: false,
            [Constant.OTHER_CURRICULUM_IND]: false
        });
        setTotalFileSize("");
        setFormData(null);
    }

    //handle event for class type and lifestyle coach
    function handleCheckBoxChange(event) {
        const { name, value, checked } = event.target;

        //console.log(name, value, checked)

        let _applicaitonObj = {};

        //reset error validation for class type and lifestyle coach
        name.indexOf("ClassType") > -1
            ? clearValidationText(Constant.NO_CLASS_TYPE_SELECTED)
            : clearValidationText(name);

        if (name === Constant.COACH_TRAINING_ENTITY_ID) {

            //clear training provider text box error message
            clearValidationText(Constant.COACH_TRAINING_ENTITY_NAME)

            _applicaitonObj = {
                ...applicationObj,
                [name]: value
            };

        } else {
            //if ClassType is other and is not checked, clear the Class_Type_Other_Name input
            if (name === Constant.CLASS_TYPE_OTHERS && !checked) {
                _applicaitonObj = {
                    ...applicationObj,
                    [name]: checked,
                    [Constant.CLASS_TYPE_OTHER_NAME]: ""
                };
            } else {
                _applicaitonObj = {
                    ...applicationObj,
                    [name]: checked
                };

                //this is setting errors for Before You Begin section when any of the check boxes are false
                if (name === Constant.ACKNOWLEDGE_SOP_IND && !checked) {
                    setErrors({
                        ...errors,
                        [Constant.ACKNOWLEDGE_SOP_IND]: Constant.ACKNOWLEDGE_SOP_IND_VALIDATE_MSG
                    })
                }
                if (name === Constant.ACKNOWLEDGE_OCA_IND && !checked) {
                    setErrors({
                        ...errors,
                        [Constant.ACKNOWLEDGE_OCA_IND]: Constant.ACKNOWLEDGE_OCA_IND_VALIDATE_MSG
                    })
                }
                if (name === Constant.ACKNOWLEDGE_HEGPIC_IND && !checked) {
                    setErrors({
                        ...errors,
                        [Constant.ACKNOWLEDGE_HEGPIC_IND]: Constant.ACKNOWLEDGE_HEGPIC_IND_VALIDATE_MSG
                    })
                }

                //Checking if Delivery attestation checkbox is checked
                if (name === Constant.DELIVERY_ATTESTATION && !checked) {
                    setErrors({
                        ...errors,
                        [Constant.DELIVERY_ATTESTATION]: Constant.DELIVERY_ATTESTATION_VALIDATE_MSG
                    })
                }
            }
        }

        if (name === Constant.COACH_TRAINING_ENTITY_ID) {
            api.getTrainingProviderLku(value).then(({ data }) => { //getting list of training providers
                setTrainingProviders(data);
                //setting coaching training entity name and coach training entity id
                _applicaitonObj = {
                    ...applicationObj,
                    [name]: value,
                    [Constant.TRAINING_PROVIDER_ID]: data[0].id
                };
                setApplicationObj(_applicaitonObj);
            });

        } else {
            setApplicationObj(_applicaitonObj);
        }
    }

    //console.log("applicationObj:", applicationObj);
    //console.log("UI custom validation errors:", errors);
    //render the main component  
    //REFACTOR later by using react context instead of props drilling
    return (
        <>
            <Layout>
                <Helmet>
                    <title>Diabetes Prevention Recognition Program Application | CDC</title>
                </Helmet>
                {isSavingApplication && (
                    <>
                        <Overlay />
                        <Spinner
                            message="Saving Application Form..."
                            backGround={true}
                            center={true}
                        />
                    </>
                )}

                {loadingApi ? (
                    <>
                        <Overlay />
                        <Spinner
                            message="Loading Application Form..."
                            backGround={true}
                            center={true}
                        />
                    </>
                ) : (
                    <>
                        {/*Intro Banner*/}
                        <IntroBanner
                            LandingImageSrc={LandingImageSrc}
                            formApprovalInfo={formApprovalInfo}
                            ApplicationFormPage={true}
                            PageTitle="Diabetes Prevention Recognition Program Application"

                        />
                        {/*End Intro Banner*/}

                        <BeforeYouBegin
                            onCheckBoxChange={handleCheckBoxChange}
                            applicationObj={applicationObj}
                            errors={errors}
                        />

                        {/*ONLY show form if Before You Begin checkboxes are checked*/}
                        {
                            applicationObj[`${Constant.ACKNOWLEDGE_SOP_IND}`] &&
                            applicationObj[`${Constant.ACKNOWLEDGE_OCA_IND}`] &&
                            applicationObj[`${Constant.ACKNOWLEDGE_HEGPIC_IND}`] &&
                            <form onSubmit={handleSubmit} autoComplete="off">
                                {/*General Info*/}
                                <div>
                                    <GeneralInfo
                                        statesData={states}
                                        stateValue={stateId}
                                        stateName={Constant.PHYSICAL_STATE_ID}
                                        stateDefaultOption="Select One..."
                                        onChange={handleChange}
                                        onKeyDown={handleOnKeyDown}
                                        onValidateBlur={handleValidateOnBlur}
                                        onStateChange={handleStateChange}
                                        onCheckUrlClick={handleCheckUrlClick}
                                        onBlurPhoneNumber={handleOnBlurPhoneNumber}
                                        onCheckboxChange={handleCheckBoxChange}
                                        applicationObj={applicationObj}
                                        organizationCategories={organizationCategories}
                                        addressObj={addressObj}
                                        onClickVerifyAddress={verifyAddress}
                                        isVerifiedAddress={isVerifiedAddress}
                                        verifyingAddress={verifyingAddress}
                                        isValidUrl={isValidUrl}
                                        errors={errors}
                                    />
                                </div>
                                {/*End General Info*/}

                                {/*Type of Delivery*/}
                                <div>
                                    <TypeOfDelivery
                                        applicationObj={applicationObj}
                                        statesData={states}
                                        programDeliveryMode={programDeliveryModes}
                                        combineDeliveryModes={combineDeliveryModes}
                                        interactionMechanismLku={interactionMechanismLku}
                                        technologyLookUp={technologyLookUp}
                                        onChange={handleChange}
                                        onCheckboxChange={handleCheckBoxChange}
                                        onOtherInputChange={hanldeOnOtherInputChange}
                                        onStateSelectChange={handleStateSelectChange}
                                        onChangeTypeOfDelivery={handleChangeTypeOfDelivery}
                                        onDeliveryModeCheckboxChange={handleChangeDeliveryModecheckBox}
                                        onIncludeEnrollmentLocations={handleChangeEnrollmentLocationRadio}
                                        onValidateBlur={handleValidateOnBlur}
                                        errors={errors}
                                    />
                                </div>
                                {/*end Type of Delivery*/}

                                {/*Program Coordinator*/}
                                <div>
                                    <ProgramCoordinator
                                        onChange={handleChange}
                                        roleTitles={roleTitles}
                                        onKeyDown={handleOnKeyDown}
                                        onValidateBlur={handleValidateOnBlur}
                                        onBlurPhoneNumber={handleOnBlurPhoneNumber}
                                        onSalutationChange={handleSalutationChange}
                                        isOtherSalutation={isOtherSalutation}
                                        applicationObj={applicationObj}
                                        errors={errors}
                                    />
                                </div>
                                {/*Program Coordinator*/}

                                {/*Secondary contact (Optional)*/}
                                <div>
                                    <SecondaryContact
                                        onChange={handleChange}
                                        onKeyDown={handleOnKeyDown}
                                        onValidateBlur={handleValidateOnBlur}
                                        onIncludeContactChange={handleContactChange}
                                        onOptionalChange={handleOnOptionalChange}
                                        onBlurPhoneNumber={handleOnBlurPhoneNumber}
                                        isCheckedSecondaryContact={isCheckedSecondaryContact}
                                        clearContactFields={clearContactFields}
                                        onSalutationChange={handleSalutationChange}
                                        isOtherSalutationSecondary={isOtherSalutationSecondary}
                                        applicationObj={applicationObj}
                                        roleTitles={roleTitles}
                                        errors={errors}
                                    />
                                </div>
                                {/*End Secondary contact (Optional)*/}

                                {/*Data Preparer*/}
                                <div>
                                    <DataPreparer
                                        applicationObj={applicationObj}
                                        onChange={handleChange}
                                        onKeyDown={handleOnKeyDown}
                                        onValidateBlur={handleValidateOnBlur}
                                        onIncludeContactChange={handleContactChange}
                                        onBlurPhoneNumber={handleOnBlurPhoneNumber}
                                        onOptionalChange={handleOnOptionalChange}
                                        isCheckedDataPreparer={isCheckedDataPreparer}
                                        clearContactFields={clearContactFields}
                                        onSalutationChange={handleSalutationChange}
                                        isOtherSalutationDataPreparer={isOtherSalutationDataPreparer}
                                        errors={errors}
                                    />
                                </div>
                                {/*End Data Preparer*/}

                                {/*Progrm Delivery Information*/}
                                <div>
                                    <ProgramDeliveryInfo
                                        applicationObj={applicationObj}
                                        onChange={handleChange}
                                        onValidateBlur={handleValidateOnBlur}
                                        onChangeCurriculum={handleOtherCurriculum}
                                        includeProjectedStartDate={Constant.INCLUDE_PROJECTED_START_DATE}
                                        includeProjectedStartDateText={Constant.INCLUDE_PROJECTED_START_DATE_TEXT}
                                        projectedStartDate={Constant.PROJECTED_START_DATE}
                                        projectedStartDateText={Constant.PROJECTED_START_DATE_TEXT}
                                        onIncludeProjectedStartDateChange={handleIncludeProjectedStartDateChange}
                                        onChangeUpload={handleChangeUpload}
                                        onCheckBoxChange={handleCheckBoxChange}
                                        isCheckedOtherCurriculum={isCheckedOtherCurriculum}
                                        isUploadDisabled={isUploadDisabled}
                                        totalFileSize={totalFileSize}
                                        classTypes={classTypes}
                                        curriculums={curriculums}
                                        coachTrainingEntityTypes={coachTrainingEntityTypes}
                                        trainingProvider={trainingProviders}
                                        errors={errors}
                                    />
                                </div>
                                {/*End Progrm Delivery Information*/}

                                {/*Certification of Application*/}
                                <div>
                                    <CertificationOfApplication
                                        applicationObj={applicationObj}
                                        onChange={handleChange}
                                        onValidateBlur={handleValidateOnBlur}
                                        onDateChange={handleDateChange}
                                        calendarDate={calendarDate}
                                        errors={errors}
                                    />
                                </div>
                                {/*End Certification of Application*/}

                                {/*Verification and Submit Application*/}
                                <div>
                                    <VerificationAndSubmit
                                        recaptchaRef={recaptchaRef}
                                    />
                                </div>
                                {/*End Verification and Submit Application*/}
                            </form>
                        }
                    </>
                )}
            </Layout>
        </>
    );
}

export default Application;