import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as api from "../../api/api";
import RegistryTable from "../ui/RegistryTable";
import "../../styles/registry.scss";
import States from "../ui/State";
import City from "../ui/City";
import { isEmptyObject, isNumeric } from "jquery";
import Spinner from "../../common/Spinner";
import Constant from "../../common/Constant";
import IntroBanner from "../ui/IntroBanner";
import LandingImageSrc from "../../images/LandingPageSite.png";
import Overlay from "../../common/Overlay";
import { toast } from "react-toastify";
import { doAnalyticsWithTimeout } from "../../common/analytics";
import { Layout } from "../layout/Layout";
import { useSetTitle } from "../../common/Util";
import PageNav from "../../common/PageNav";
import { BsSearch } from "react-icons/bs";

const Registry = () => {
    const [registryData, setRegistryData] = useState({}),
        defaultState = {id: "All States", description: "All States"},
        [filteredData, setfilteredData] = useState([]),
        [loadingApi, setLoadingApi] = useState(true),
        [downloadingExcel, setDownloadingExcel] = useState(false),
        [registryObj, setRegistryObj] = useState({}),
        [states, setStates] = useState([]),
        [state, setState] = useState(
            defaultState
        ),
        [cities, setCities] = useState([]),
        [allStatesSelected, setAllStatesSelected] = useState(true),
        [fullPlusRegData, setFullPlusRegData] = useState([]),
        [fullRegData, setFullRegData] = useState([]), //full recognition data
        [prelimRegData, setPrelimRegData] = useState([]), //preliminary recognition data
        [regularRegData, setRegularRegData] = useState([]), //regular  recognition data
        [combinedData, setCombinedData] = useState([]), //all three regonitiondata combined after filtering
        [searchData, setSearchData] = useState([]), //use to hold the entire dataset for the search
        [searchText, setSearchText] = useState(""),
        [toggleSortOrder, setToggleSortOrder] = useState(true), //this will determine if it's asc or desc
        [sortBy, setSortBy] = useState(Constant.ORG_NAME),
        [isSearching, setIsSearching] = useState(false),
        [noDataFound, setNoDataFound] = useState(false),
        [timer, setTimer] = useState(null),
        [perPage, setPerPage] = useState(50),
        [offset, setOffset] = useState(0),
        [pageCount, setPageCount] = useState(0),
        [resetPageCount, setResetPageCount] = useState(0),
        [firstRow, setFirstRow] = useState(0),
        [lastRow, setLastRow] = useState(0),
        [pageEntriesCount, setPageEntriesCount] = useState({
            currentEntries: 0,
            totalCurrentEntries: 0,
            totalEntries: 0,
            currentPage: 1
        }),
        [isLoaded, setIsLoaded] = useState(false),
        [showResetLabel, setShowResetLabel] = useState(false);

    const updatePaginationData = useCallback(
        () => {
            const data = combinedData;
            const slice = data.slice(offset, offset + perPage);
            let lastRowOffset = offset + perPage;
            setFirstRow(offset);
            setLastRow(lastRowOffset > data.length ? data.length : lastRowOffset);
            setfilteredData(slice.length > 0 ? slice : data); //set the new sliced data to local state to pass down into component render
            setPageCount(Math.ceil(data.length / perPage)); //update page count
        },
        [combinedData, perPage, offset]
    );

    const changePageNumber = (newPageNumber) => {
        let pageNumberOffset = newPageNumber - 1;
        let _offset = Math.ceil(pageNumberOffset * perPage);

        setOffset(_offset);

        //set pagination count when clicking through
        setPageEntriesCount({
            currentEntries: _offset === 0 ? 1 : _offset + 1,
            totalCurrentEntries:
                _offset + perPage > combinedData.length
                    ? combinedData.length
                    : _offset + perPage,
            totalEntries: combinedData.length,
            currentPage: pageNumberOffset === 0 ? 1 : pageNumberOffset + 1
        });
    }

    useEffect(
        () => {
            //analytics
            if (isEmptyObject(registryData)) {
                //get registry data
                api
                    .getRegistryLku()
                    .then(data => {
                        if (data.length > 0) {
                            setRegistryData(data);
                        } else {
                            console.error(data.exceptionMesssage);
                            toast.error(`${Constant.ERROR_MSG} (${data.exceptionMesssage})`);
                        }
                    })
                    .catch(err => {
                        //handle error
                        console.error(err);
                        toast.error(`${Constant.ERROR_MSG} (${err})`);
                    });
                //get state look up data
                api
                    .getStateLku()
                    .then(response => {
                        setStates(response.data);
                    })
                    .catch(err => {
                        //handle error
                        console.error(err);
                        toast.error(`${Constant.ERROR_MSG} (${err})`);
                    });
            } else {

                doAnalyticsWithTimeout(Constant.ANALYTICS_PARMS);
                updatePaginationData();
                setIsLoaded(true);

                const timer = setTimeout(() => {
                    if (loadingApi === true) {
                        setLoadingApi(false);
                        toast.error(`${Constant.ERROR_MSG} (No data found)`);
                    }
                }, 15000);
                return () => clearTimeout(timer);
            }
        },
        [registryData, updatePaginationData, loadingApi]
    );
    //execute custom js 
    useEffect(() => {
        if (isLoaded) {
            //creating button to append to text to <button> for 508
            // const button = document.querySelector('.react-search-field-button'),
            //     span = document.createElement("span");

            // span.classList.add("sr-only");
            // span.innerText = "Search Registry";
            // button.appendChild(span);
        }
    }, [isLoaded]);

    //click event for pagination
    const handlePageClick = e => {
        const selectedPage = e.selected;

        let _offset = Math.ceil(selectedPage * perPage);

        setOffset(_offset);
        setResetPageCount(e.selectedPage);

        //set pagination count when clicking through
        setPageEntriesCount({
            currentEntries: _offset === 0 ? 1 : _offset + 1,
            totalCurrentEntries:
                _offset + perPage > combinedData.length
                    ? combinedData.length
                    : _offset + perPage,
            totalEntries: combinedData.length,
            currentPage: e.selected
        });
    };

    //handle per page change
    const handlePageChange = e => {
        setPerPage(Number(e.target.value));
        setResetPageCount(0); //reset back to page1
        // console.log(e.target.value, pageEntriesCount.currentPage);
        //console.log(offset, perPage);
        setPageEntriesCount({
            currentEntries: 1,
            totalCurrentEntries:
                e.target.value < combinedData.length
                    ? e.target.value
                    : combinedData.length,
            totalEntries: combinedData.length,
            currentPage: 1
        });

        //set back to page 0 to render table
        setOffset(0);
    };

    //initial load of table
    if (isEmptyObject(registryObj) && !isEmptyObject(states)) {
        setRegistryObj({
            stateAbbr: states[1].stateAbbr,
            city: "all"
        });
    } else if (isEmptyObject(cities) && !isEmptyObject(registryData)) {
        //get city
        let combinedRegonitionArrayData = [];
        //get related state data to filter for unique cities
        const cityFilteredeData = registryData.filter(
            state => state.state === registryObj.stateAbbr
        );

        if (
            fullPlusRegData.length === 0 &&
            fullRegData.length === 0 &&
            prelimRegData.length === 0 &&
            regularRegData.length === 0
        ) {
            combinedRegonitionArrayData = filterAllRecognitionArray(registryData);
        }

        //console.log(cityFilteredeData);

        //condition for if filtered data match
        if (cityFilteredeData.length > 0) {
            //filter cities list to get unique values
            const distinctCityFilteredData = [
                ...new Set(cityFilteredeData.map(data => data.city.trim()))
            ];

            setCities(distinctCityFilteredData); //set distinct list of city
            setCombinedData(combinedRegonitionArrayData); //after filtering of the three recognition type, set the new result as web hook as combinedData
            setSearchData(combinedRegonitionArrayData); //this is use for the dynamic search of the entire dataset

            //setCurrentSelectionData(combinedRegonitionArrayData); //set current selection to handle the state/cities when the search is empty
            updatePaginationData(); //update pagination data
            setPageCount(Math.ceil(combinedRegonitionArrayData.length / perPage));

            //set the page count text
            setPageEntriesCount({
                currentEntries: offset === 0 ? 1 : offset,
                totalCurrentEntries:
                    offset + perPage > combinedRegonitionArrayData.length
                        ? combinedRegonitionArrayData.length
                        : offset + perPage,
                totalEntries: combinedRegonitionArrayData.length,
                currentPage: 1
            });
            setLoadingApi(false);
        }
    }

    //handle changes to set registry object
    function handleChange(event) {
        const { name, value } = event;
        let _value = undefined;
        _value = //setting value to get either state abbreviation (.i.g. AL) or city name
            value != "undefined" && isNaN(+value) // eslint-disable-line
                ? value
                : states.find(state => state.id === value).stateAbbr;
        _value === undefined
            ? _value = event.target.value
            : _value = _value;
        setShowResetLabel(true);

        //filter the cities data to set into the UI table
        filterStatesCitiesData(name, _value);
        //registry obj used to hold current selection of state or city
        if (name === "stateAbbr" && event.target === undefined) {
            _value.toLowerCase() === "all states"
                ? setState(defaultState)
                : setState({
                    id: _value,
                    description: states.find(state => state.stateAbbr === _value).description
                });
            //state dropdown
            setRegistryObj({
                ...registryObj,
                [name]: _value,
                city: "all" //if state selection, reset city seleciton to all
            });
        } else {
            setRegistryObj({
                //city drodown
                ...registryObj,
                [event.target.name]: _value
            });
        }
    }

    const refreshRegistryTable = dataObjArray => {
        let combinedRegonitionArrayData = [];

        combinedRegonitionArrayData = filterAllRecognitionArray(dataObjArray); //filter and combine recognition array
        setCombinedData(combinedRegonitionArrayData); //set data and re-render table
        //reset page count 
        setPageEntriesCount({
            currentEntries: 1,
            totalCurrentEntries:
                offset + perPage > combinedRegonitionArrayData.length
                    ? combinedRegonitionArrayData.length
                    : perPage,
            totalEntries: combinedRegonitionArrayData.length,
            currentPage: pageEntriesCount.currentPage
        });
    };

    //filter state and city method
    function filterStatesCitiesData(name, value) {
        //console.log(registryObj);
        let _filteredData = [];
        setNoDataFound(false);
        //determine if drop down is state or city to filter and update the table data
        if (name === "stateAbbr") {
            //set data for ALL STATE
            if (value.toLowerCase() === "all states") {
                refreshRegistryTable(registryData); //refresh registry table with new data
                setResetPageCount(0); //reset back to page1
                setAllStatesSelected(true);
            } else {
                setAllStatesSelected(false);
                setResetPageCount(0); //reset back to page1

                //get city data by state
                const cityFilteredeData = registryData.filter(
                    state => state.state === value
                );

                //sort the city data by state ascending
                const sortCityFilteredData = []
                    .concat(cityFilteredeData)
                    .sort(compareValues("city", "asc"));

                refreshRegistryTable(sortCityFilteredData); //refresh registry table with new data

                //filter cities list to get unique city name only, use for the drop down
                const distinctCityFilteredData = [
                    ...new Set(sortCityFilteredData.map(data => data.city.trim()))
                ];

                setCities(distinctCityFilteredData); //set filtered citities data
            }
        } else {
            if (value === "all") {
                //filter state by all city, we are referencing to the registryObj here to get the current state
                _filteredData = registryData.filter(
                    state => state.state.trim() === registryObj.stateAbbr
                );
            } else {
                //filter state by single city
                _filteredData = registryData.filter(
                    state =>
                        state.city.trim() === value && state.state === registryObj.stateAbbr
                );
            }

            //set combined data to refresh table
            setCombinedData(_filteredData);

            //set pagination result count
            setPageEntriesCount({
                currentEntries: 1,
                totalCurrentEntries:
                    offset + perPage > _filteredData.length
                        ? _filteredData.length
                        : offset + perPage,
                totalEntries: _filteredData.length,
                currentPage: pageEntriesCount.currentPage
            });
        }

        //reset text input for search when filtering by state or city
        setSearchText("");
        setShowResetLabel(false)
    }

    //utility method to sort the data
    function compareValues(key, order = "asc") {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            const keyA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
            const keyB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
            let comparison = 0;
            if (keyA > keyB) {
                comparison = 1;
            } else if (keyA < keyB) {
                comparison = -1;
            }
            return order === "desc" ? comparison * -1 : comparison;
        };
    }

    //method to break up registry into three categories and handle deep sorting (full plus reg, Full regonition, preliminary regonition and regular regonition)
    function filterAllRecognitionArray(data) {
        //filter to get new lists of data with recognition
        const fullPlusRegArray = [],
            fullRegArray = [],
            preRegArray = [],
            regularArray = [];

        const filteredData = data;
        //filter and add an entry to the array for each different type of recognition
        Object.entries(filteredData).forEach(([key, value]) => {
            if (value.orgName.indexOf("Full Plus Recognition") !== -1) {
                fullPlusRegArray.push({ ...value, isFullPlusRecognition: true });
            } else if (value.orgName.indexOf("Full Recognition") !== -1) {
                fullRegArray.push({ ...value, isFullRecognition: true });
            } else if (value.orgName.indexOf("Preliminary Recognition") !== -1) {
                preRegArray.push({ ...value, isPreliminaryRecognition: true });
            } else {
                regularArray.push({
                    ...value,
                    regularRecognition: true
                });
            }
        });

        const fontAwesomeStar =
            '<b><i class="fa fa-star" aria-hidden="true"></i>&nbsp;',
            fontAwesomeDoubleStar =
                '<b><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i>&nbsp;',
            fontAwesomeCircle =
                '<b><i class="fa fa-circle" aria-hidden="true"></i>&nbsp;';

        //replacing the fa icons in full plus recognition data for more accurate sort, the star icons will be added back  after the filtering
        Object.entries(fullPlusRegArray).forEach(([key, value]) => {
            value.orgName = value.orgName
                .toString()
                .replace(fontAwesomeDoubleStar, "");
        });
        //replacing the fa icons in full recognition data for more accurate sort, the star icon will be added back  after the filtering
        Object.entries(fullRegArray).forEach(([key, value]) => {
            value.orgName = value.orgName.toString().replace(fontAwesomeStar, "");
        });
        //replacing the fa icons in preliminary recognition data for more accurate sort, the circle icon will be added back after the filtering
        Object.entries(preRegArray).forEach(([key, value]) => {
            value.orgName = value.orgName.toString().replace(fontAwesomeCircle, "");
        });

        //default ascending sort of all data
        const fullPlusRegonitionArr = []
            .concat(fullPlusRegArray)
            .sort(compareValues(Constant.ORG_NAME, "asc"));

        const fullRegonitionArr = []
            .concat(fullRegArray)
            .sort(compareValues(Constant.ORG_NAME, "asc"));

        const preliminaryRegonitionArr = []
            .concat(preRegArray)
            .sort(compareValues(Constant.ORG_NAME, "asc"));

        const regularRegonitionArr = []
            .concat(regularArray)
            .sort(compareValues(Constant.ORG_NAME, "asc"));

        const combinedRegonitionArr = [
            ...fullPlusRegonitionArr,
            ...fullRegonitionArr,
            ...preliminaryRegonitionArr,
            ...regularRegonitionArr
        ];

        //set the three categories of recognition data into its own array to handle deep sorting: full recognition, prelimiary recognition, and regular recognition
        setFullPlusRegData(fullPlusRegonitionArr);
        setFullRegData(fullRegonitionArr);
        setPrelimRegData(preliminaryRegonitionArr);
        setRegularRegData(regularRegonitionArr);

        //return all three categories into one array to render
        return combinedRegonitionArr;
    }

    //sort the table
    const handleSort = sortBy => {
        setSortBy(sortBy);

        let data = [];
        //toggle for asc and desc ordering
        if (toggleSortOrder) {
            data = [
                ...fullPlusRegData.sort(compareValues(sortBy, "desc")),
                ...fullRegData.sort(compareValues(sortBy, "desc")),
                ...prelimRegData.sort(compareValues(sortBy, "desc")),
                ...regularRegData.sort(compareValues(sortBy, "desc"))
            ];
            //setting sorted data

            setToggleSortOrder(false);
        } else {
            data = [
                ...fullPlusRegData.sort(compareValues(sortBy, "asc")),
                ...fullRegData.sort(compareValues(sortBy, "asc")),
                ...prelimRegData.sort(compareValues(sortBy, "asc")),
                ...regularRegData.sort(compareValues(sortBy, "asc"))
            ];
            //setting sorted data
            setToggleSortOrder(true);
        }

        //console.log(data);
        //set new data to re-render component
        setCombinedData(data);
    }

    //handle clicking on link for multiorg, will filter by the id from the link
    const handleClickMultiOrg = (multiOrgId) => {
        if (multiOrgId != "") {
            setSearchText("");
            setShowResetLabel(true);
            let multiOrgRegData = registryData.filter(item =>
                item.formattedClassType.toLowerCase().includes(`id="${multiOrgId}"`))//return all org that matches this ID attribute, it comes from DB string with hyperlink
            resetRegistryData(); //reset back to default state
            refreshRegistryTable(multiOrgRegData); //reload ui
        } 
    }

    //event for clear button, reset to default data
    const resetRegistryData = () => {
        setAllStatesSelected(true); //state dropdown
        setState(defaultState);
        setRegistryObj({ //city dropdown
            ...registryObj,
            "city": "all"
        });
        setSearchText(""); //search input
        setNoDataFound(false); //set this to false, otherwise, the dataset will be blank
        refreshRegistryTable(registryData) //refresh UI
    }

    //add worker to optimize filtering request on dynamic search
    const worker = new Worker("./worker.js");

    const onSearchChange = event => {
        //quick delay to optimize typing
        setSearchText(event.target.value);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setIsSearching(true);
            }, 400)
        );

        setTimer(
            setTimeout(() => {
                //send to worker.js to process filtering of the searched data
                worker.postMessage([searchData, event.target.value]);
            }, 1000)
        );
    };

    //worker response for search input
    worker.onmessage = e => {
        //e.data[0] = the array of object, e.data[1] = our search query string result
        if (e.data[0].length > 0 && e.data[1] !== "") {
            //show found filtered data
            setNoDataFound(false);
            setIsSearching(false);

            //set page count to match filtered result
            setPageEntriesCount({
                currentEntries: 1,
                totalCurrentEntries:
                    offset + perPage > e.data[0].length
                        ? e.data[0].length
                        : offset + perPage,
                totalEntries: e.data[0].length,
                currentPage: 1
            });
            setResetPageCount(offset);

            //set combinedData to reload the registry table
            filterAllRecognitionArray(e.data[0]); //breaking the result into the 4 category, full plus, plus and etc...
            setCombinedData(e.data[0]);
            setAllStatesSelected(true);
        } else if (e.data[1] === "") {
            //reset ui back to original search data if empty search
            setNoDataFound(false);
            setIsSearching(false);

            //set page count to match orignal result
            setPageEntriesCount({
                currentEntries: 1,
                totalCurrentEntries:
                    offset + perPage > searchData.length
                        ? searchData.length
                        : offset + perPage,
                totalEntries: searchData.length
            });

            refreshRegistryTable(registryData); //refresh registry table data with all results
            setResetPageCount(0); //reset page count back to 1
            setOffset(offset); //reset data to page 1 on table
            setAllStatesSelected(true); //use to reset states and city dropdown to default
        } else {
            //no data found
            setIsSearching(false);
            setNoDataFound(true);

            //set all pagination back to 0, due to no data
            setPageEntriesCount({
                currentEntries: 0,
                totalCurrentEntries: 0,
                totalEntries: 0
            });
            setResetPageCount(0);
            setAllStatesSelected(true);
        }
    };

    //download registry to excel event
    function onClickDownloadRegistry() {
        setDownloadingExcel(true);
        api
            .downloadRegistry()
            .then(() => {
                setDownloadingExcel(false);
            })
            .catch(err => {
                console.error(err);
                setDownloadingExcel(false);
            });
    }

    return (
        <Layout>
            <div>
                {loadingApi ? (
                    <>
                        <Overlay />
                        <Spinner
                            message="Loading Registry Data..."
                            backGround={true}
                            center={true}
                        />
                    </>
                ) : (
                    <>
                        <Helmet>
                            <title>
                                Diabetes Prevention Recognition Program Registry | CDC
                            </title>
                        </Helmet>
                        {downloadingExcel && (
                            <>
                                <Overlay />
                                <Spinner
                                    message="Downloading Full Registry..."
                                    backGround={true}
                                    center={true}
                                />
                            </>
                        )}
                        <IntroBanner
                            LandingImageSrc={LandingImageSrc}
                            RegistryPage={true}
                            PageTitle="Registry of All Recognized Organizations"
                        />
                        <div className="">
                            <div className="card">
                                <h2 className="card-header bg-gray-l2 h5">
                                    Show organizations by location:
                                </h2>
                                <div className="bg-gray-l2 card-body">
                                    <div className="float-right">
                                        <button
                                            onClick={onClickDownloadRegistry}
                                            className="btn"
                                            disabled={downloadingExcel}
                                        >
                                            <span className="x32 fill-p cdc-icon-excel mr-2" />
                                            {downloadingExcel
                                                ? "Downloading Full Registry..."
                                                : "Download full Registry"}
                                        </button>
                                    </div>
                                    <div className="form-group form-inline">
                                        {/*Render state component*/}
                                        <States
                                            statesData={[defaultState].concat(states)}
                                            onChange={handleChange}
                                            value={state}
                                            disableOptionOutlyingIsland="UM"
                                            name="stateAbbr"
                                            defaultOption={defaultState}
                                            selectClassName="col-12"
                                            allStatesSelected={allStatesSelected}
                                        />

                                        <City
                                            onChange={handleChange}
                                            value={registryObj.city}
                                            cityData={cities}
                                            name="city"
                                            label="City"
                                            allStatesSelected={allStatesSelected}
                                        />
                                        <div className="col-12 pl-0 mt-2 ml-4">
                                            <span>OR</span>
                                        </div>
                                        <h2 className="col-12 pl-0 mt-2 h5">
                                            Search by organization name:
                                        </h2>
                                    </div>
                                    <div className="d-flex">
                                        <label className="col-md-5 d-flex" aria-label="search by organization">
                                            <span className="accessible-hide">
                                                Search by organization name:
                                            </span>
                                            {/* <SearchField
                                                placeholder="Search..."
                                                onChange={onSearchChange}
                                                value=""
                                                searchText={searchText}
                                                classNames="registrySearchInput"
                                            /> */}
                                            <input 
                                                type="text"
                                                placeholder={'Search...'}
                                                className="form-control react-search-field-input"
                                                onChange={onSearchChange}
                                                value={searchText}
                                            />
                                            <button
                                                className="react-search-field-button btn btn-light"
                                                style={{ 
                                                    backgroundColor: '#fff',
                                                    border: '1px solid #ced4da'
                                                }}
                                            >
                                                <BsSearch />
                                                <span className="sr-only">Search Registry</span>
                                            </button>
                                        </label>
                                        <button className="btn btn-primary ml-3 mb-1" onClick={() => { resetRegistryData(); setShowResetLabel(false) }}>Clear</button>
                                    </div>
                                    <div className="col-4 p-0 text-center">{(searchText.length > 0 || showResetLabel ) && 'Reset your search by pressing "Clear"'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-teal-t col-12 ml-0 pb-4 row pt-3">
                            <div className="col-4">
                                <div className="small font-weight-bold">
                                    <i className="fa fa-star" /> <i className="fa fa-star" />{" "}
                                    Organization has achieved full plus CDC recognition
                                </div>
                                <div className="small font-weight-bold">
                                    <i className="fa fa-star" /> Organization has achieved full CDC
                                    recognition
                                </div>
                                <div className="small font-weight-bold">
                                    <i className="fa fa-circle" /> Organization has preliminary
                                    recognition
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-inline">
                                    <label htmlFor="perPageResult" className="mr-2">
                                        Results per page:
                                    </label>
                                    <select //default per page
                                        id="perPageResult"
                                        onChange={handlePageChange}
                                        value={perPage}
                                        className="col-2 form-control form-inline mr-3"
                                    >
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                    </select>
                                    {`${pageEntriesCount.currentEntries} - ${pageEntriesCount.totalCurrentEntries
                                        } of ${pageEntriesCount.totalEntries}`}
                                </div>
                            </div>
                            <div className="col-4 text-right">
                                <div className="small font-weight-bold">
                                Umbrella Hub Organizations are organizations designated to provide administrative
                                infrastructure support.
                                </div>{" "}
                                <div className="small font-weight-bold">
                                    <span style={{ color: "#2b78e4" }}>▲</span>Data sorted by this
                                    column (click column header to sort)
                                </div>
                            </div>
                        </div>
                        {!noDataFound &&
                            pageCount > 1 && ( //hide pagination if no data
                                <div className="bg-white col-12 p-3">
                                    <div>
                                        <PageNav
                                            className="mr-3"
                                            changePageNumber={changePageNumber}
                                            pageNumber={pageEntriesCount.currentPage}
                                            maxRows={combinedData.length}
                                            pageSize={pageCount}
                                            firstRow={firstRow}
                                            lastRow={lastRow}
                                        />
                                        {/* <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={3}
                                            forcePage={resetPageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination mb-0"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link mr-3 rounded"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link ml-3 rounded"}
                                        /> */}
                                    </div>
                                </div>
                            )}

                        {/*render registry table*/}
                        <RegistryTable
                            registryData={filteredData}
                            onSort={handleSort}
                            sortBy={sortBy}
                            onClickMultiOrg={handleClickMultiOrg}
                            toggleSortOrder={toggleSortOrder}
                            isSearching={isSearching}
                            noDataFound={noDataFound}
                        />
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Registry;
