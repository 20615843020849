import React from "react";
import Constant from "../../common/Constant";
import RadioInput from "../../common/RadioInput";

const EnrollmentLocation = ({
  title,
  applicationObj,
  onChange,
  errors
}) => {
  return (
    <>
      <div className="form-group mb-0" tabIndex="0">
        <div className="ml-3">
          <RadioInput
              id={title}
              onChange={onChange}
              name={title}
              introText={Constant.ENROLLMENT_LOCATION_QUESTION}
              isRequired={true}
              valueOne={true}
              valueTwo={false}
              ariaGroupLabel={'Enrollment Location'}
              error={errors[`${Constant.ENROLLMENT_LOCATION}`]}
              ariaSelected={""}
          />
        </div>
      </div>
    </>
  );
};

export default EnrollmentLocation;